import {
  DefaultButton,
  IconButton,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useAddDataAssetToUseCaseMutation,
  useGetAllTagsQuery,
  useGetUseCaseQuery,
  useUpdateUseCaseMutation,
} from "../../../api/cockpit";
import { TopContainer } from "../../../components/common/TopContainer";
import {
  descDetailStyle,
  itemStyleDetail,
  itemStyleMain,
  linkItemsContainer,
  numericalSpacingStackTokens,
  SpinnerStyles,
} from "../../../helpers/commonStyles";
import { Details } from "./details";
import { useBoolean } from "@fluentui/react-hooks";
import { UseCase as UseCaseType } from "../../../types/usecase";
import { EditableTextField } from "../../../components/common/EditableTextField";
import { RichTextEditor } from "../../../components/common/RichTextEditor";
import { AssetList } from "../../../components/cockpit/AssetList";
import { SelectLinkedDataAssets } from "../../../components/cockpit/SelectLinkedDataAssets";
import { CommentWrapper } from "../../../components/common/CommentWrapper";
import { useGetCommentsQuery } from "../../../api/comments";

export const UseCase: FunctionComponent = () => {
  const { t } = useTranslation();

  const params = useParams();
  const useCaseData = useGetUseCaseQuery(params.id ?? skipToken);
  const tagsData = useGetAllTagsQuery();
  const [updateUseCase] = useUpdateUseCaseMutation();
  const [addAssetToUseCase] = useAddDataAssetToUseCaseMutation();
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [useCase, setUseCase] = useState<UseCaseType>({} as UseCaseType);
  const [linkAssetsVisible, { setTrue: showAssetLinking, setFalse: hideAssetLinking }] =
    useBoolean(false);
  const [newlyLinkedAssetIDs, setNewlyLinkedAssetIDs] = useState<string[]>([]);
  const { data } = useGetCommentsQuery(params.id ?? skipToken, {
    pollingInterval: 10000,
  }); // Added interval for comments api so that we have latest comments getting updated on frontend for consistency.

  const updateUseCaseCall = () => {
    useCase && useCase.id && updateUseCase(useCase);
  };

  useEffect(() => {
    if (useCaseData.isSuccess) {
      setUseCase(useCaseData.data);
      setValue(useCaseData.data.description!);
      setTitle(useCaseData.data.title);
    }
  }, [useCaseData.isSuccess, useCaseData.data]);

  return (
    <TopContainer title={t("usecase_detail_title")} backButton={true}>
      {useCaseData.isLoading || useCaseData.isLoading ? (
        <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />
      ) : (
        <>
          <EditableTextField
            id="useCaseTitle"
            value={title}
            onBlur={updateUseCaseCall}
            onChange={(
              _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => {
              if (newValue) {
                setTitle(newValue);
                setUseCase({ ...useCase, title: newValue });
              }
            }}
            styles={{
              root: { marginTop: "18px", marginBottom: "4px" },
              field: { fontSize: "18px", fontWeight: "900" },
            }}
          />
          <Stack horizontal tokens={numericalSpacingStackTokens}>
            <section style={itemStyleMain}>
              <label style={{ ...descDetailStyle, marginLeft: 8 }}>{t("description")}</label>

              <RichTextEditor
                id="editor"
                toggleEditor={true}
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  setUseCase({
                    ...useCase,
                    description: newValue,
                  });
                }}
                onSave={() => {
                  updateUseCaseCall();
                }}
                onCancel={() => {
                  if (useCaseData && useCaseData.data && useCaseData.data.description) {
                    setValue(useCaseData.data.description);
                    setUseCase({ ...useCaseData.data });
                  }
                }}
              />

              <Separator />
              <Stack styles={linkAssetsVisible ? undefined : linkItemsContainer}>
                <label style={{ ...descDetailStyle, marginLeft: 10 }}>{t("assets_linked")}</label>
                {linkAssetsVisible ? (
                  <>
                    <Stack tokens={{ childrenGap: 6 }} style={{ marginLeft: 10 }}>
                      <SelectLinkedDataAssets
                        linkedIds={newlyLinkedAssetIDs}
                        setLinkedIds={setNewlyLinkedAssetIDs}
                      />
                      <Stack
                        horizontal
                        styles={{ root: { marginBottom: 10 } }}
                        tokens={{ childrenGap: 15 }}
                      >
                        <PrimaryButton
                          text={t("save")}
                          onClick={() => {
                            Promise.all(
                              newlyLinkedAssetIDs.map((id) =>
                                addAssetToUseCase({ dataAssetId: id, useCaseId: useCase.id })
                              )
                            ).then((results) => {
                              console.log("results");
                            });
                          }}
                        />
                        <DefaultButton text={t("cancel")} onClick={hideAssetLinking} />
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  <IconButton
                    iconProps={{ iconName: "Add" }}
                    title={t("add")}
                    ariaLabel={t("add")}
                    onClick={showAssetLinking}
                  />
                )}
              </Stack>
              {useCaseData.data?.linked_assets && (
                <AssetList dataAssets={useCaseData.data?.linked_assets} isDragAndDrop={false} />
              )}
              <Separator />
              <CommentWrapper
                comments={data?.comments || []}
                subjectId={useCase.id}
                subjectType="use_case"
                commentThreadId={data?.id}
              />
            </section>
            <section style={itemStyleDetail}>
              <Details
                useCase={useCase}
                setUseCase={setUseCase}
                tags={tagsData.data}
                updateUseCase={updateUseCaseCall}
              />
            </section>
          </Stack>
        </>
      )}
    </TopContainer>
  );
};
