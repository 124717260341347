import { Stack } from "@fluentui/react";
import { selectLatestUpload } from "../../store/slices/upload";
import { DataGrid } from "../../components/quick-analysis/DataGrid";
import { AnalysisSidebar } from "./analysis-sidebar/AnalysisSidebar";
import { useAppSelector } from "../../store/hooks";

export const AnalysisResult = () => {
  const uploadedFile = useAppSelector(selectLatestUpload);
  if (!uploadedFile) return null;
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={{ root: { margin: 20 } }}
      tokens={{ childrenGap: 20 }}
    >
      <Stack horizontal verticalAlign="center" horizontalAlign="center">
        <DataGrid height={800} width={1000} />
        <AnalysisSidebar />
      </Stack>
    </Stack>
  );
};
