import { IStackProps } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";
export const getIssueIconContainerProps = (issueType: "info" | "warning"): IStackProps => ({
  tokens: {
    childrenGap: 5,
  },
  horizontal: true,
  verticalAlign: "center",
  horizontalAlign: "end",
  styles: {
    root: {
      color: issueType === "warning" ? "#D83B01" : "#7863C3",
      minWidth: 20,
    },
  },
});

export const overallQualityContainerStyles = () => ({
  root: { marginLeft: 10, minHeight: 52, borderBottom: "1px solid rgb(161, 159, 157)" },
});

export const getQualityContainerProps = (isActiveFilter: boolean): IStackProps => ({
  horizontal: true,
  styles: {
    root: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      marginTop: 8,
      marginLeft: 4,
      marginRight: 4,
      boxShadow: isActiveFilter ? "0 0 0 1px #D2D0CE" : "none",
      borderBottom: `1px solid ${dqcPalette.neutralTertiary}`,
      backgroundColor: isActiveFilter ? "#F0F0F0" : "white",
      ":hover": {
        backgroundColor: isActiveFilter ? "white" : "#F0F0F0",
      },
    },
  },
});
