import {
  MissingIssue,
  DuplicateIssue,
  InconsistentIssue,
  OutlierIssue,
  PiiIssue,
  QuickAnalysisIssue,
  StandardCheck,
} from "../../../types/quickAnalysis";

export const addIssueId = (
  issues: (MissingIssue | DuplicateIssue | InconsistentIssue | OutlierIssue | PiiIssue)[]
): QuickAnalysisIssue[] => {
  return issues.map((issue) => {
    const column: number = issue.type !== "duplicate" ? issue.column : issue.columns[0];
    return {
      ...issue,
      id: `${issue.row}::${column}::${issue.type}`,
      isIgnored: !!issue?.isIgnored,
    };
  });
};

export const isColumnSelected = (columnIndex: number, check: StandardCheck): boolean => {
  return !!check.columns.find((checkColumn) => checkColumn.index === columnIndex)?.selected;
};
