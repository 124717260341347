import { Overlay, Stack, Text } from "@fluentui/react";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import pluginGif from "../../assets/analyzing.gif";
import { t } from "i18next";

export const Analyzing = () => {
  return (
    <Overlay isDarkThemed={false}>
      <Image
        styles={{ root: { margin: "auto", marginTop: 180 } }}
        imageFit={ImageFit.contain}
        width={80}
        src={pluginGif}
        alt="loading icon"
      />
      <Stack
        styles={{
          root: { margin: "auto", marginTop: 20, width: "80%", alignSelf: "center" },
        }}
      >
        <Text>{t("analyzing")}</Text>
      </Stack>
    </Overlay>
  );
};
