import { Stack, IconButton, PrimaryButton, Text } from "@fluentui/react";
import { doQuickAnalysis } from "../../store/slices/quickAnalysis";
import { clearAllFiles, selectLatestUpload } from "../../store/slices/upload";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { t } from "i18next";
import { DataGrid } from "../../components/quick-analysis/DataGrid";

export const AnalysisPreview = () => {
  const uploadContainer = useAppSelector(selectLatestUpload);
  const dispatch = useAppDispatch();
  if (!uploadContainer) return null;
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={{ root: { margin: 20 } }}
      tokens={{ childrenGap: 20 }}
    >
      <Text>{t("preview")}</Text>
      <DataGrid height={400} width={600} />
      <Stack horizontal verticalAlign="center">
        <Text>{uploadContainer.fileName}</Text>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <IconButton
            onClick={() => {
              dispatch(clearAllFiles());
            }}
            iconProps={{ iconName: "cancel" }}
          />
          <PrimaryButton
            onClick={() => {
              dispatch(doQuickAnalysis(uploadContainer));
            }}
          >
            {t("analyze")}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
