import React, { useEffect, useState } from "react";
import { Nav, INavLink, INavStyles, INavLinkGroup } from "@fluentui/react/lib/Nav";
import { DefaultPalette, Icon, IStackStyles, Shimmer, Stack } from "@fluentui/react";
import i18n from "./i18n";
import { useNavigate, useLocation } from "react-router-dom";

import logo from "./assets/logo.svg";
import { useGetInstallationsQuery } from "./api/installation";
import { useTranslation } from "react-i18next";
import { footerStyle } from "./helpers/commonStyles";
import { fakeWidth } from "./helpers/misc";

type SidebarProps = {
  hasSession?: boolean;
  hasInstallation?: boolean;
  userName: string;
  isLoading?: boolean;
};

const navStyles: Partial<INavStyles> = {
  root: {
    background: DefaultPalette.neutralLight,
    width: "100%",
    boxSizing: "border-box",
    border: "none",
    overflow: "visible",
  },
};

const stackStyles: Partial<IStackStyles> = {
  root: {
    overflow: "auto",
    height: "100%",
  },
};

const generateSlugName = (path: string, defaultName: string = "quickstart"): string => {
  const pathParts = path.split("/").filter((p) => p.length > 0);
  return pathParts[0] || defaultName;
};

export const QUISidebar = ({ userName, hasInstallation, isLoading }: SidebarProps) => {
  let location = useLocation();
  const { t } = useTranslation();
  const urlSlug = generateSlugName(location.pathname);
  const installationData = useGetInstallationsQuery();
  const dropdownFullSlug = location.pathname;
  let navigate = useNavigate();
  let dropdownData: string[] = [];
  dropdownData = JSON.parse(localStorage.getItem("selectedKeyArray") || "{}");

  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKeyArray") ? dropdownData[1] : urlSlug
  );
  useEffect(() => {
    if (urlSlug === "dashboard" || urlSlug === "cockpit") {
      if (dropdownFullSlug.split("/").length > 2) {
        setSelectedKey(dropdownData[1]);
      } else {
        setSelectedKey(dropdownData[0]);
      }
    } else {
      setSelectedKey(urlSlug);
      localStorage.removeItem("selectedKeyArray");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug]);

  const navLinkGroup: INavLinkGroup = {
    links: [
      {
        name: i18n.t("quick_start"),
        icon: "LightningBolt",
        key: "quickstart",
        url: "/",
        disabled: false,
      },
      {
        name: i18n.t("quick_analysis"),
        icon: "NewAnalyticsQuery",
        key: "quick-analysis",
        url: "/quick-analysis",
        disabled: false,
      },
      {
        name: i18n.t("plugins_and_connections"),
        disabled: true,
        url: "",
      },
      {
        name: "Plugins",
        key: "plugins",
        icon: "PlugDisconnected",
        url: "/plugins",
      },
      {
        name: i18n.t("connection_title"),
        key: "connections",
        icon: "ConnectVirtualMachine",
        url: "/connections",
      },
      {
        name: i18n.t("data_management"),
        disabled: true,
        url: "",
      },
      {
        name: i18n.t("surveys_title"),
        key: "surveys",
        icon: "ClipboardList",
        url: "/surveys",
      },
      {
        name: i18n.t("performance_learning"),
        key: "learning-journeys",
        icon: "D365TalentLearn",
        url: "/learning-journeys",
      },
      {
        name: i18n.t("missions"),
        key: "missions",
        icon: "WorkFlow",
        url: "/missions",
      },
      {
        name: "Cockpit",
        key: "cockpit",
        url: "/cockpit",
        expandAriaLabel: "more ...",
        links: [
          {
            name: i18n.t("use-cases"),
            key: "cockpit-use-cases",
            icon: "FileComment",
            url: "/cockpit/use-cases",
          },
          {
            name: i18n.t("assets"),
            key: "cockpit-assets",
            icon: "Database",
            url: "/cockpit/assets",
          },
        ],
        isExpanded: dropdownData[0] === "cockpit" ? true : false,
      },
      {
        name: i18n.t("settings_caps"),
        disabled: true,
        url: "",
      },
      {
        name: userName,
        key: "profile",
        icon: "UserGauge",
        url: "/profile",
        disabled: false,
      },
      {
        name: i18n.t("company_info"),
        key: "company",
        icon: "CityNext2",
        url: "/company",
      },
    ].map((link) => ({
      ...link,
      disabled: link.disabled === undefined ? !hasInstallation : link.disabled,
    })),
  };

  return (
    <Stack styles={stackStyles}>
      <img className="App-logo" src={logo} alt="logo" />
      {isLoading ? (
        <>
          <div style={{ minWidth: 225 }}>
            {[...Array(14)].map((a, i) => (
              <Shimmer
                key={`${a}-${i}-shimmer`}
                width={`${fakeWidth[i]}%`}
                height={45}
                style={{ marginBottom: 20 }}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <Nav
            onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault();
              let keyArray: string[] = [];

              if (item?.key) {
                setSelectedKey(item?.key ?? "");
                if (item.key.indexOf("cockpit") !== -1) {
                  keyArray.push("cockpit", item.key);
                  localStorage.setItem("selectedKeyArray", JSON.stringify(keyArray));
                } else if (item.key.indexOf("dashboard") !== -1) {
                  keyArray.push("dashboard", item.key);
                  localStorage.setItem("selectedKeyArray", JSON.stringify(keyArray));
                } else {
                  localStorage.removeItem("selectedKeyArray");
                }
              }
              navigate(item?.url ?? "/");
            }}
            selectedKey={selectedKey ? selectedKey : ""}
            styles={navStyles}
            groups={[navLinkGroup]}
          />
          {hasInstallation ? (
            <>
              <Stack.Item styles={footerStyle}>
                {t("we")} <Icon iconName="Heart" />{" "}
                {installationData.data ? installationData.data?.name : "you"} ©{" "}
                {new Date().getFullYear()}{" "}
                <a href="https://dqc.ai" title="DQC">
                  DQC
                </a>
              </Stack.Item>{" "}
            </>
          ) : (
            <>
              <Stack.Item styles={footerStyle}>
                {t("we")} <Icon iconName="Heart" />
                {" Good Data "}© {new Date().getFullYear()}{" "}
                <a href="https://dqc.ai" title="DQC">
                  DQC
                </a>
              </Stack.Item>{" "}
            </>
          )}
        </>
      )}
    </Stack>
  );
};
