import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";

import {
  selectAnalysisConfigBySheetId,
  setAnalysisConfig,
} from "../../store/slices/analysisConfig";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";

import { RootState } from "../../store/store";
import { t } from "i18next";
import { QuickAnalysisConfig } from "../../types/quickAnalysis";
import { selectLatestUpload } from "../../store/slices/upload";
import { doQuickAnalysis, setSidebarView } from "../../store/slices/quickAnalysis";
import { getQuickAnalysisConfigFromDataContainer } from "../../helpers/quickAnalysis/getQuickAnalysisConfigFromDataContainer";
import { MissingSettings } from "./settings/MissingSettings";
import { DuplicateSettings } from "./settings/DuplicateSettings";
import { InconsistentSettings } from "./settings/InconsistentSettings";
import { SensitiveSettings } from "./settings/SensitiveSettings";
import { OutlierSettings } from "./settings/outlierSettings/OutlierSettings";
import { SidebarContainerStyles } from "../../routes/quick-analysis/analysis-sidebar/AnalysisSidebar.styles";

export const Settings = () => {
  const dispatch = useAppDispatch();

  const latestUpload = useAppSelector(selectLatestUpload);
  const analysisConfig = useAppSelector<QuickAnalysisConfig | undefined>((state: RootState) =>
    selectAnalysisConfigBySheetId(state, latestUpload?.fileName || "")
  );

  const [localAnalysisConfig, setLocalAnalysisConfig] = useState<QuickAnalysisConfig | undefined>(
    undefined
  );
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    setLocalAnalysisConfig(analysisConfig);
  }, [analysisConfig]);

  const keyDownListener = (e: KeyboardEvent) => {
    if (e.key === "s" && (e.ctrlKey || e.metaKey || e.altKey)) {
      e.preventDefault();
      saveSettings();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownListener);
    return () => {
      document.removeEventListener("keydown", keyDownListener);
    };
  });

  if (!latestUpload) return null;
  if (!localAnalysisConfig) {
    return <Spinner size={SpinnerSize.large} label={t("analysis_running")} />;
  }

  const headerRow = latestUpload.headerRow;

  const saveSettings = async () => {
    dispatch(setAnalysisConfig(localAnalysisConfig));
    await dispatch(doQuickAnalysis(latestUpload));
    dispatch(setSidebarView("qualitySummary"));
  };

  const SaveButton = () => (
    <PrimaryButton
      iconProps={{ iconName: "Save" }}
      split
      aria-roledescription="config splitter"
      onClick={saveSettings}
    >
      {t("save")}
    </PrimaryButton>
  );
  const DontSaveButton = () => (
    <DefaultButton
      onClick={() => {
        dispatch(setSidebarView("qualitySummary"));
      }}
    >
      {t("dont_save")}
    </DefaultButton>
  );
  const BackButton = () => (
    <DefaultButton
      styles={{ root: { border: 0 } }}
      onClick={() => {
        if (JSON.stringify(localAnalysisConfig) === JSON.stringify(analysisConfig))
          dispatch(setSidebarView("qualitySummary"));
        else setShowDialog(true);
      }}
      iconProps={{ iconName: "backArrow" }}
    >
      {t("back")}
    </DefaultButton>
  );
  const RestoreDefaultsButton = () => (
    <DefaultButton
      onClick={async () => {
        const defaultAnalysisConfig = getQuickAnalysisConfigFromDataContainer(latestUpload);
        setLocalAnalysisConfig(defaultAnalysisConfig);
      }}
      style={{ borderWidth: 0, paddingRight: 0, textDecoration: "underline" }}
    >
      {t("restore_defaults")}
    </DefaultButton>
  );

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: t("not_saved_title"),
    closeButtonAriaLabel: "Close",
    subText: t("leave_without_saving"),
  };
  const hideColumns = false;

  const dimensionSettingsProps = {
    analysisConfig: localAnalysisConfig,
    setAnalysisConfig: setLocalAnalysisConfig,
    headerRow,
    hideColumns,
  };
  const disableColumnSelector = false;

  return (
    <Stack styles={SidebarContainerStyles}>
      <Dialog
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter styles={{ action: { width: "100%" } }}>
          <Stack
            horizontal={true}
            styles={{
              root: {
                horizontal: true,
                verticalAlign: "center",
                horizontalAlign: "end",
                gap: "15px",
                justifyContent: "center",
              },
            }}
          >
            <DontSaveButton />
            <SaveButton />
          </Stack>
        </DialogFooter>
      </Dialog>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { marginBottom: 10, marginTop: 10 } }}
      >
        <BackButton />
        <SaveButton />
      </Stack>
      <Stack tokens={{ childrenGap: 8 }}>
        <MissingSettings {...dimensionSettingsProps} />
        <InconsistentSettings {...dimensionSettingsProps} />
        <OutlierSettings {...dimensionSettingsProps} />
        <DuplicateSettings
          {...dimensionSettingsProps}
          disableColumnSelector={disableColumnSelector}
        />

        <SensitiveSettings {...dimensionSettingsProps} />
      </Stack>
      <Stack horizontal horizontalAlign="end">
        <RestoreDefaultsButton />
      </Stack>
    </Stack>
  );
};
