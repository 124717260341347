import { useEffect, useRef } from "react";
import {
  getUploadDataFrameFromCSVFile,
  getUploadDataFrameFromExcelFile,
} from "../../helpers/quickAnalysis/fileUploader";
import { AnalysisPreview } from "./AnalysisPreview";
import { PrimaryButton, Stack, Text } from "@fluentui/react";
import { UploadDataContainer } from "../../types/fileUploader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLatestUpload, setUploadFile } from "../../store/slices/upload";
import { t } from "i18next";

export const FileUploader = () => {
  const inputElement = useRef<HTMLInputElement>(null);
  const uploadedFile: UploadDataContainer | undefined = useAppSelector(selectLatestUpload);
  const dispatch = useAppDispatch();
  const fileName = "";

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files;
    if (!filesList?.[0]) return;
    const file = filesList[0];
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      if (file.name.match(/\.csv$/i)) {
        const container = getUploadDataFrameFromCSVFile(e, file.name);
        dispatch(setUploadFile(container));
      } else if (file.name.match(/\.xlsx?$/i)) {
        const container = getUploadDataFrameFromExcelFile(e, file.name);
        dispatch(setUploadFile(container));
      }
    };
    if (file.name.match(/\.csv$/i)) reader.readAsText(file);
    else if (file.name.match(/\.xlsx?$/i)) reader.readAsBinaryString(file);
  };
  // TODO why do we need this useEffect?
  useEffect(() => {
    if (fileName === null && inputElement.current) inputElement.current.value = "";
  }, [fileName]);
  if (uploadedFile) return <AnalysisPreview />;
  return (
    <Stack
      styles={{ root: { marginTop: 200 } }}
      horizontal
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Stack.Item>
        <Text styles={{ root: { lineHeight: 40, paddingRight: 10 } }}>{t("select_file")}</Text>
      </Stack.Item>
      <Stack.Item>
        <input
          ref={inputElement}
          type="file"
          onChange={handleFileChange}
          accept=".csv, .xlsx"
          hidden={true}
        />
        <PrimaryButton
          onClick={async () => {
            inputElement.current?.click();
          }}
        >
          {t("browse")}
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};
