import {
  BaseCheck,
  DQCColumn,
  MissingCheck,
  QuickAnalysisConfig,
  UploadDataContainer,
  InconsistentCheck,
  OutlierCheck,
  DuplicateCheck,
  SensitiveCheck,
} from "../../types/quickAnalysis";
import { EMPTY_CELL_VALUES } from "./qualityChecks/constants";

export const getQuickAnalysisConfigFromDataContainer = (
  dataContainer: UploadDataContainer
): QuickAnalysisConfig => {
  const allColumns: DQCColumn[] = dataContainer.headerRow.map((name, index) => ({
    index,
    name,
    selected: true,
  }));
  const baseCheck: BaseCheck = { enabled: true, analyzeAllColumns: true, columns: allColumns };
  const missingCheck: MissingCheck = {
    ...baseCheck,
    name: "missing",
    meta: { valuesConsideredMissing: EMPTY_CELL_VALUES },
  };
  const inconsistentCheck: InconsistentCheck = {
    ...baseCheck,
    name: "inconsistent",
    meta: {},
  };
  const outlierCheck: OutlierCheck = {
    ...baseCheck,
    name: "outlier",
    meta: {
      checkSign: false,
      checkOutliers: true,
      considerZeros: false,
      iqrCutoff: 2,
    },
  };
  const duplicateCheck: DuplicateCheck = {
    ...baseCheck,
    name: "duplicate",
    meta: { threshold: 0 },
  };
  const sensitiveCheck: SensitiveCheck = {
    ...baseCheck,
    name: "sensitive",
    meta: {
      categoryToCheck: [
        "Email address",
        "Social security number",
        "Passport number",
        "Credit card",
        "Phone number",
        "IP address",
        "Name",
        "IBAN",
      ],
    },
  };
  return {
    fileInfo: { name: dataContainer.fileName },
    checks: [missingCheck, inconsistentCheck, outlierCheck, duplicateCheck, sensitiveCheck],
  };
};
