import { useAppSelector } from "../../../store/hooks";
import { selectSidebarView } from "../../../store/slices/quickAnalysis";
import { Settings } from "../../../components/quick-analysis/Settings";
import { QualitySummary } from "./QualitySummary";

export const AnalysisSidebar = () => {
  const sidebarView = useAppSelector(selectSidebarView);
  if (sidebarView === "settings") return <Settings />;
  return <QualitySummary />;
};
