import {
  getCheckFromAnalysisConfig,
  getUpdateCheck,
} from "../../../helpers/quickAnalysis/analyisConfigHelpers";
import { QualityDimensionSettingsProps } from "../../../types/quickAnalysis";
import { ColumnSelector } from "./ColumnSelector";

export const InconsistentSettings = ({
  analysisConfig,
  setAnalysisConfig,
  hideColumns,
}: QualityDimensionSettingsProps) => {
  const inconsistentCheck = getCheckFromAnalysisConfig(analysisConfig, "inconsistent");
  const updateCheck = getUpdateCheck(analysisConfig, setAnalysisConfig, "inconsistent");
  return (
    <ColumnSelector check={inconsistentCheck} updateCheck={updateCheck} hideColumns={hideColumns} />
  );
};
