import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  QuickAnalysisIssue,
  QuickAnalysisConfig,
  UploadDataContainer,
} from "../../types/quickAnalysis";
import { getQuickAnalysisConfigFromDataContainer } from "../../helpers/quickAnalysis/getQuickAnalysisConfigFromDataContainer";
import { findIssues } from "../../helpers/quickAnalysis/qualityChecks/analyze";
import { handleArrowPress } from "./issueNavigator";

type QuickAnalysisSlice = {
  status: "selectFile" | "analyzing" | "results";
  sidebarView: "settings" | "qualitySummary";
  issues: QuickAnalysisIssue[];
  activeFilter?: string;
};
const quickAnalysis: QuickAnalysisSlice = {
  status: "selectFile",
  issues: [],
  sidebarView: "qualitySummary",
};

export const doQuickAnalysis = createAsyncThunk<
  { issues: QuickAnalysisIssue[]; analysisConfig: QuickAnalysisConfig },
  UploadDataContainer,
  { state: RootState }
>("quickAnalysis/doQuickAnalysis", async (dataContainer: UploadDataContainer, { getState }) => {
  const state = getState();
  const analysisConfig =
    state.analysisConfig.entities[dataContainer.fileName] ??
    getQuickAnalysisConfigFromDataContainer(dataContainer);
  const issues = findIssues(dataContainer, analysisConfig);
  return { issues, analysisConfig };
});

export const quickAnalysisSlice = createSlice({
  name: "quickAnalysis",
  initialState: quickAnalysis,
  reducers: {
    setQuickAnalysisStatus: (state, { payload }: PayloadAction<QuickAnalysisSlice["status"]>) => {
      state.status = payload;
    },
    setActiveFilter: (state, { payload }: PayloadAction<QuickAnalysisSlice["activeFilter"]>) => {
      const newActiveFilter = state.activeFilter === payload ? undefined : payload;
      state.activeFilter = newActiveFilter;
    },
    setSidebarView: (state, { payload }: PayloadAction<QuickAnalysisSlice["sidebarView"]>) => {
      state.sidebarView = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doQuickAnalysis.pending, (state) => {
      state.status = "analyzing";
    });
    builder.addCase(doQuickAnalysis.fulfilled, (state, { payload }) => {
      state.status = "results";
      state.issues = payload.issues;
    });
    builder.addCase(doQuickAnalysis.rejected, (state, { error }) => {
      console.error(error);
      state.status = "selectFile";
    });
    builder.addCase(handleArrowPress.fulfilled, (state, { payload }) => {
      if (payload) state.activeFilter = payload.meta?.type;
    });
  },
});

const selectQuickAnalysiseSlice = (state: RootState) => state.quickAnalysis;

export const { setQuickAnalysisStatus, setActiveFilter, setSidebarView } =
  quickAnalysisSlice.actions;
export const selectLatestQuickAnalysisStatus = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.status
);
export const selectSidebarView = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.sidebarView
);

export const selectLatestQuickAnalysisIssues = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.issues
);
const selectQualityDimension = (_: RootState, dimension: QuickAnalysisIssue["type"]) => dimension;
export const selectIssuesByDimension = createSelector(
  selectLatestQuickAnalysisIssues,
  selectQualityDimension,
  (issues, dimension) => {
    return issues.filter((issue) => issue.type === dimension);
  }
);

export const selectActiveFilter = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.activeFilter
);
