import { FormEvent, FunctionComponent, useEffect, useState, useRef } from "react";
import { Task } from "../../../types/missions";
import { QualityDimension } from "../../../types/quality";
import { Responsible } from "../../../types/responsibles";
import { getResourceByDimension, GET_CARD_RESOURCE_TEXT } from "../../../helpers/resources-helper";
import { processTaskFields } from "../../../helpers/mission-helper";
import moment from "moment";
import JoditEditor from "jodit-react";
import { TaskAddEditModalProps } from "../../../types/missions";
import {
  DefaultButton,
  ITextFieldStyles,
  Modal,
  PrimaryButton,
  TextField,
  Label,
  Icon,
  Stack,
  Dropdown,
  IDropdownOption,
  IStackStyles,
  IButtonStyles,
} from "@fluentui/react";
import * as AdaptiveHtml from "adaptive-html";
import * as AdaptiveCards from "adaptivecards";
import { useCreateTaskMutation, useUpdateTaskMutation } from "../../../api/mission";
import { dqcRESTApi } from "../../../api/dqcREST";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Jodit } from "jodit";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { TaskModalFieldContainer } from "./TaskModalFieldContainer";
import { TaskModalProfileSuggestions } from "./TaskModalProfileSuggestions";
import { contentStyles, iconStyle } from "./CommonTaskStyles";
import { useGetIdentityConnectionsQuery } from "../../../api/installation";

const settingContainer: IStackStyles = {
  root: {
    padding: "3%",
  },
};

const modalField: Partial<ITextFieldStyles> = {
  root: {},
};

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};

const modalPrimaryButton: IButtonStyles = {
  root: {
    color: "white",
  },
};

const buttonContainer: IStackStyles = {
  root: {
    justifyContent: "flex-end",
  },
};

const responsibleStyle: IStackStyles = {
  root: {
    cursor: "pointer",
    padding: "5px",
  },
};

//DropDown Options
const recurrenceOptions: IDropdownOption[] = [
  { key: "DAILY", text: i18n.t("daily") },
  { key: "WEEKLY", text: i18n.t("weekly") },
  { key: "MONTHLY", text: i18n.t("monthly") },
  { key: "YEARLY", text: i18n.t("yearly") },
  { key: "", text: i18n.t("no") },
];

export const TaskAddEditModal: FunctionComponent<TaskAddEditModalProps> = ({
  modalState,
  modelTitle,
  task,
  datasets,
  isEdit,
  missionId,
  secondaryButtonOnClick,
}) => {
  const { t } = useTranslation();
  const now = new Date(+new Date() + 60000 * 10);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [datasetOptions, setDatasetOptions] = useState<IDropdownOption[]>([]);
  const [qualityDimensionOptions, setQualityDimensionOptions] = useState<IDropdownOption[]>([]);
  const [datasetSelected, setDatasetSelected] = useState<string | null>(null);
  const [dimensionSelected, setDimensionSelected] = useState<keyof typeof QualityDimension | null>(
    null
  );
  const [messageText, setMessageText] = useState<string | undefined>(undefined);
  const [recurrencePatternFreq, setRecurrencePatternFreq] = useState<string>("");
  const [recurrencePatternCount, setRecurrencePatternCount] = useState<string | null>(null);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isAutogenerated, setIsAutogenerated] = useState<boolean>(false);
  const [messageJSON, setMessageJSON] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState<Responsible[]>([]);
  const [profiles, setProfiles] = useState<Responsible[]>([]);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(
    moment.utc(now).local().format(moment.HTML5_FMT.DATETIME_LOCAL)
  );
  const [taskCreateReq] = useCreateTaskMutation();
  const [taskUpdateReq] = useUpdateTaskMutation();
  const [profileName, setProfileName] = useState<string>("");

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [identityFlag, setIdentityFlag] = useState<boolean>(false);
  const getIdentityConnections = useGetIdentityConnectionsQuery("");

  const config = {
    buttons: ["bold", "italic", "ul", "fullsize"],
    buttonsXS: ["bold", "italic", "ul", "fullsize"],
    readonly: false,
    placeholder: "Start typings...",
  };

  AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text: string, result: any) {
    result.outputHtml = DOMPurify.sanitize(marked.parse(text));
    result.didProcess = true;
  };

  useEffect(() => {
    if (!getIdentityConnections.isLoading && getIdentityConnections.isSuccess) {
      if (getIdentityConnections.data.length === 0) {
        setValidationMessage(t("identity_connector_validation"));
        setIdentityFlag(true);
      }
    }
  }, [getIdentityConnections]);

  useEffect(() => {
    const data = datasets.map((dataset) => {
      return { key: dataset.id, text: dataset.name, ...dataset };
    });
    setDatasetOptions(data);
    const dimensionData: IDropdownOption[] = [];
    Object.entries(QualityDimension).forEach(([dimensionKey, dimensionValue]) => {
      if (dimensionValue !== QualityDimension.UNDEFINED) {
        dimensionData.push({ key: dimensionKey, text: t(dimensionValue) });
      }
    });
    setQualityDimensionOptions(dimensionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets]);

  useEffect(() => {
    if (isEdit) {
      setDatasetSelected(task ? task?.dataset_id : null);
      setDimensionSelected(task ? task?.quality_dimension : null);
      setSelectedResponsible(task ? task?.responsibles : []);
      if (task?.is_recurring) {
        let { freq, count } = rRuleSplitStrElements(task.recurrence_pattern);
        setRecurrencePatternFreq(freq);
        setRecurrencePatternCount(count);
      }
      setStartDate(moment.utc(now).local().format(moment.HTML5_FMT.DATETIME_LOCAL));
      setEndDate(moment.utc(task?.end_date).local().format(moment.HTML5_FMT.DATETIME_LOCAL));
      setIsActive(true);
      setIsAutogenerated(task ? task?.is_autogenerated : false);
      setMessageJSON(task ? task?.card_message : {});
      if (isAutogenerated && task?.message === "") {
        setMessageText(GET_CARD_RESOURCE_TEXT); //set default content for message
      } else {
        setMessageText(task?.message ? task?.message : "");
      }
    } else {
      setMessageText(GET_CARD_RESOURCE_TEXT); //set default content for message
    }

    const adaptiveCard = new AdaptiveCards.AdaptiveCard();
    // Parse the card payload
    var card = {
      type: "AdaptiveCard",
      version: "1.3",
      body: task?.card_message,
    };
    adaptiveCard.parse(card);
    const doc = adaptiveCard.render();
    setContent(doc?.innerHTML!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const handleCloseModal = () => {
    secondaryButtonOnClick();
  };

  function rRuleSplitStrElements(rruleStr?: string) {
    if (!rruleStr?.length) return { freq: "", count: null };
    let spstr = rruleStr.split(";");
    let freq = spstr[0].split("=")[1];
    let count = spstr.length > 1 ? spstr[1].split("=")[1] : null;
    return { freq, count };
  }

  function validateModal() {
    if (!datasetSelected) {
      setValidationMessage(t("add_task_dataset_validation"));
      return;
    }
    if (!dimensionSelected) {
      setValidationMessage(t("add_task_dimension_validation"));
      return;
    }
    if (selectedResponsible.length === 0) {
      setValidationMessage(t("add_task_responsible_validation"));
      return;
    }
    if (!startDate) {
      setValidationMessage(t("add_task_startdate_validation"));
      return;
    } else {
      if (moment(startDate).isBefore()) {
        setValidationMessage(t("add_task_startdate_past_validation"));
        return;
      }
    }
    if (recurrencePatternFreq !== "") {
      if (!endDate) {
        setValidationMessage(t("add_task_enddate_validation"));
        return;
      }
      let sdate = new Date(startDate);
      let edate = new Date(endDate);
      if (sdate > edate) {
        setValidationMessage(t("add_task_enddate_early_validation"));
        return;
      }
    }
    setValidationMessage("");
    return true;
  }

  const getTaskModel = (
    responsibles: Responsible[],
    message: string,
    startDateTS: string,
    endDateTS: string
  ): Task => {
    let r_pattern = "";
    if (recurrencePatternFreq) r_pattern += `FREQ=${recurrencePatternFreq};`;
    if (recurrencePatternCount) r_pattern += `COUNT=${recurrencePatternCount}`;
    return {
      mission_id: missionId,
      dataset_id: datasetSelected!,
      quality_dimension: dimensionSelected!,
      responsibles: responsibles!,
      message: message,
      card_message: messageJSON,
      is_recurring: recurrencePatternFreq !== "" ? true : false,
      recurrence_pattern: r_pattern,
      start_date: startDateTS,
      end_date: endDateTS,
      is_active: isActive,
      is_autogenerated: isAutogenerated,
    };
  };

  const handleClick = async () => {
    if (validateModal()) {
      setBtnLoading(true);
      const { message, startDateTS, endDateTS } = processTaskFields(
        messageText,
        startDate!,
        endDate
      );
      let taskModel = getTaskModel(selectedResponsible, message, startDateTS, endDateTS);
      if (isEdit && task?.id) {
        taskModel.id = task.id; // set task id for edit task
        await taskUpdateReq({ id: task.id, taskModel: taskModel }).unwrap();
      } else {
        await taskCreateReq(taskModel).unwrap();
      }
      setBtnLoading(false);
      handleCloseModal();
    }
  };

  const onDimensionChange = (dimension: string) => {
    setMessageText(getResourceByDimension(dimension));
  };

  const getGroupsAndPeople = async (keyword: string) => {
    setProfileName(keyword);
    if (keyword) {
      const resp = await dqcRESTApi.get("/colleagues-groups", {
        params: { q: encodeURIComponent(keyword) },
      });
      setProfiles(resp.data);
    } else {
      setProfiles([]);
    }
  };

  const onChipClose = (id: string) => {
    const removeChipData = selectedResponsible.filter((item) => item.id !== id);
    setSelectedResponsible(removeChipData);
  };

  return (
    <Modal isOpen={modalState} containerClassName={contentStyles.container}>
      <Stack horizontal styles={headerContainer}>
        <Label>{modelTitle}</Label>
        <Icon iconName="ChromeClose" onClick={() => handleCloseModal()} styles={iconStyle} />
      </Stack>
      <div style={{ border: "1px solid #DDDDDD" }} />
      <Stack styles={settingContainer}>
        {/* First row */}
        <p style={{ color: "red", marginLeft: "11px" }}>{validationMessage}</p>

        <TaskModalFieldContainer label={t("add_task_dataset")}>
          <Dropdown
            placeholder={t("add_task_select_option_placeholder")}
            defaultSelectedKey={datasetSelected}
            options={datasetOptions}
            onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
              setDatasetSelected(item?.key);
            }}
            ariaLabel={t("add_task_dataset")}
          />
        </TaskModalFieldContainer>
        <TaskModalFieldContainer label={t("add_task_dimension")}>
          <Dropdown
            placeholder={t("add_task_select_option_placeholder")}
            defaultSelectedKey={dimensionSelected}
            options={qualityDimensionOptions}
            onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
              setDimensionSelected(item?.key);
              onDimensionChange(item?.key);
            }}
            ariaLabel={t("add_task_dimension")}
          />
        </TaskModalFieldContainer>
        <TaskModalFieldContainer label={t("add_task_responsible")}>
          <Stack>
            <TextField
              name="responsible"
              styles={modalField}
              placeholder={t("add_task_search_users")}
              value={profileName}
              onChange={(e: any) => {
                getGroupsAndPeople(e.target.value);
              }}
              disabled={identityFlag}
            />
          </Stack>
          <Stack>
            {profiles.map((item, index) => (
              <Stack
                key={index}
                styles={responsibleStyle}
                className="responsible"
                onClick={() => {
                  if (!selectedResponsible.some((data) => data.id === item.id)) {
                    setSelectedResponsible((prevState: any) => [...prevState, item]);
                    setProfileName("");
                  }
                  setProfiles([]);
                }}
              >
                {item.name}
              </Stack>
            ))}
          </Stack>
        </TaskModalFieldContainer>

        {selectedResponsible ? (
          <TaskModalProfileSuggestions
            selectedResponsible={selectedResponsible}
            onChipClose={onChipClose}
          ></TaskModalProfileSuggestions>
        ) : null}
        <TaskModalFieldContainer label={t("add_task_message")}>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newHTMLContent) => {
              // @ts-ignore-next
              setMessageText(Jodit.modules.Helpers.stripTags(newHTMLContent));
              setContent(newHTMLContent);
              const cardJSON = AdaptiveHtml.toJSON(newHTMLContent).body;
              setMessageJSON(cardJSON);
            }} // preferred to use only this option to update the content for performance reasons
          ></JoditEditor>
        </TaskModalFieldContainer>
        <TaskModalFieldContainer label={t("add_task_isrecurring")}>
          <Dropdown
            placeholder={t("add_task_select_option_placeholder")}
            defaultSelectedKey={recurrencePatternFreq}
            options={recurrenceOptions}
            onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
              setRecurrencePatternFreq(item?.key);
            }}
            ariaLabel={t("add_task_isrecurring")}
          />
        </TaskModalFieldContainer>

        {recurrencePatternFreq !== "" && recurrencePatternFreq !== null ? (
          <TaskModalFieldContainer label={t("add_task_stopafter")}>
            <input
              id="stop-after"
              type="number"
              placeholder={t("add_task_stopafter_placeholder")}
              onChange={(e) => setRecurrencePatternCount(e.target.value)}
              value={recurrencePatternCount ? recurrencePatternCount : ""}
              style={{ height: "28px", width: "-webkit-fill-available" }}
            />
          </TaskModalFieldContainer>
        ) : null}

        <TaskModalFieldContainer label={t("add_task_startdate")}>
          <input
            name="start-date"
            type="datetime-local"
            placeholder={t("add_task_startdate_placeholder")}
            value={startDate ? startDate : ""}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ height: "28px", width: "-webkit-fill-available" }}
          />
        </TaskModalFieldContainer>

        {recurrencePatternFreq !== "" && recurrencePatternFreq !== null ? (
          <TaskModalFieldContainer label={t("add_task_enddate")}>
            <input
              name="end-date"
              type="datetime-local"
              placeholder={t("add_task_enddate_placeholder")}
              value={endDate ? endDate : ""}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ height: "28px", width: "-webkit-fill-available" }}
            />
          </TaskModalFieldContainer>
        ) : null}
        <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }} styles={buttonContainer}>
          <PrimaryButton
            text={
              isEdit ? (btnLoading ? t("saving") : t("save")) : btnLoading ? t("adding") : t("add")
            }
            styles={modalPrimaryButton}
            onClick={handleClick}
            disabled={identityFlag}
          />
          <DefaultButton text={t("cancel")} onClick={() => handleCloseModal()} />
        </Stack>
      </Stack>
    </Modal>
  );
};
