import { Icon, Separator, Stack, Text, TooltipHost } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  selectActiveFilter,
  selectIssuesByDimension,
  setActiveFilter,
} from "../../../store/slices/quickAnalysis";
import { t } from "i18next";
import { capitalizeFirstLetter } from "../../../utils/strings";
import { getQualityContainerStyle, iconStyle } from "./QualityDimension.styles";
import { useState } from "react";
import {
  ISSUE_TYPE_ICON_DISABLED_MAP,
  ISSUE_TYPE_ICON_MAP,
  QUALITY_DIMENSION_TOOLTIPS,
} from "../../../helpers/quickAnalysis/qualityChecks/constants";
import { defaultTooltipProps } from "../../../components/quick-analysis/settings/Settings.styles";
import { selectAnalysisConfigBySheetId } from "../../../store/slices/analysisConfig";
import { selectLatestUpload } from "../../../store/slices/upload";
import { QuickAnalysisConfig } from "../../../types/quickAnalysis";
import { WarningIssues } from "../../../components/quick-analysis/WarningIssues";
import { InfoIssues } from "../../../components/quick-analysis/InfoIssues";
import {
  handleClickOnQualityDimension,
  selectHighlightedIssue,
} from "../../../store/slices/issueNavigator";
import { IssueExplanation } from "../../../components/quick-analysis/IssueExplanation";

const mytooltipprops = JSON.parse(JSON.stringify(defaultTooltipProps));
mytooltipprops.calloutProps.calloutMaxWidth = 120;
export interface QualityDimensionProps {
  qualityDimension: "missing" | "duplicate" | "inconsistent" | "outlier" | "sensitive";
}

export const QualityDimension = ({ qualityDimension }: QualityDimensionProps) => {
  const iconName: string | undefined = ISSUE_TYPE_ICON_MAP.get(qualityDimension);
  const issuesForDimension = useAppSelector((state: RootState) =>
    selectIssuesByDimension(state, qualityDimension)
  );
  const highlightedIssue = useAppSelector(selectHighlightedIssue);
  const dispatch = useAppDispatch();
  const latestUpload = useAppSelector(selectLatestUpload);
  const analysisConfig = useAppSelector<QuickAnalysisConfig | undefined>((state: RootState) =>
    selectAnalysisConfigBySheetId(state, latestUpload?.fileName || "")
  );
  const enabled = analysisConfig?.checks.find((check) => check.name === qualityDimension)?.enabled;
  const [isHovered, setisHovered] = useState<boolean>(false);
  const activeFilter = useAppSelector(selectActiveFilter);
  const iconNameDisabled: string | undefined = ISSUE_TYPE_ICON_DISABLED_MAP.get(qualityDimension);

  const isActiveFilter = activeFilter === qualityDimension;

  const onQualityDimensionClick = () => {
    if (!enabled || !issuesForDimension.length) return;
    dispatch(setActiveFilter(qualityDimension));
    dispatch(handleClickOnQualityDimension(qualityDimension));
  };
  return (
    <TooltipHost
      content={
        t(QUALITY_DIMENSION_TOOLTIPS[qualityDimension]) ||
        QUALITY_DIMENSION_TOOLTIPS[qualityDimension]
      }
      tooltipProps={mytooltipprops}
    >
      <Stack
        onMouseEnter={() => enabled && setisHovered(true)}
        onMouseLeave={() => enabled && setisHovered(false)}
        verticalFill
        style={{
          color: enabled ? "unset" : "rgba(171, 167, 167, 1)",
          pointerEvents: enabled ? "unset" : "none",
        }}
      >
        {qualityDimension === "sensitive" && (
          <Separator
            styles={{
              root: {
                marginTop: -10,
                marginBottom: -10,
              },
            }}
          />
        )}
        <Stack
          verticalAlign="center"
          styles={getQualityContainerStyle(isActiveFilter, isHovered)}
          onClick={onQualityDimensionClick}
        >
          <Stack
            horizontal
            grow
            horizontalAlign="space-between"
            verticalAlign="center"
            styles={{ root: { marginLeft: 20, minHeight: 44 } }}
          >
            <Icon iconName={enabled ? iconName : iconNameDisabled} styles={iconStyle} />
            <Text block nowrap style={{ color: enabled ? "unset" : "rgba(171, 167, 167, 1)" }}>
              {capitalizeFirstLetter(t(qualityDimension))}
            </Text>
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
              {enabled && (
                <>
                  <WarningIssues issues={issuesForDimension} />
                  <InfoIssues issues={issuesForDimension} />
                </>
              )}
            </Stack>
          </Stack>
          {highlightedIssue?.type === qualityDimension && isActiveFilter && (
            <IssueExplanation issue={highlightedIssue} />
          )}
        </Stack>
      </Stack>
    </TooltipHost>
  );
};
