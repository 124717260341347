import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { FormEvent, FunctionComponent, useState } from "react";
import Tracking, { LoginSignupEventType } from "../../tracking/tracking";

import { Link } from "react-router-dom";
import { Node } from "../../types/auth";
import i18n from "../../i18n";
import { socialLoginOIDCrg } from "./social-logins";
import { t } from "i18next";
import { useBoolean } from "@fluentui/react-hooks";

interface EmailOrSSOState {
  nodes: Node[];
  signUp?: boolean;
  updateEmailChecked?: (bool: boolean, emailStr: string) => void;
  enabled?: boolean;
}
export const EmailOrSSO: FunctionComponent<EmailOrSSOState> = ({
  nodes,
  signUp,
  updateEmailChecked,
  enabled,
}) => {
  let [email] = nodes.filter((n) => n.attributes.name === "identifier");
  if (!email) [email] = nodes.filter((n) => n.attributes.name === "traits.email");
  const [password] = nodes.filter((n) => n.attributes.name === "password");
  const [submit] = nodes.filter(
    (n) => n.attributes.value === "password" && n.attributes.type === "submit"
  );
  const [emailStr, setEmailStr] = useState(
    email.attributes.value || localStorage.getItem("email") || ""
  );
  const ssoNodes = nodes.filter(
    (n) =>
      n.group === "oidc" &&
      n.attributes &&
      n.attributes.value &&
      !n.attributes.value.match(socialLoginOIDCrg)
  );
  const ssoIdentifiers = ssoNodes.map((oidc) => oidc.attributes.value);
  const [emailChecked, { toggle: setEmailChecked }] = useBoolean(false);
  const [displaySSO, { setTrue: showSSO }] = useBoolean(false);
  const [selectedSSO, setSelectedSSO] = useState<Node>();
  const btnStyle = { marginTop: "10px", width: "100%" };

  const checkMail = () => {
    const tld = emailStr.split("@").pop()!.split(".")[0];
    const ssoMatch = ssoIdentifiers.findIndex((identifier) => identifier === tld);

    if (ssoMatch !== -1) {
      setSelectedSSO(ssoNodes[ssoMatch]);
      showSSO();
    } else if (updateEmailChecked) updateEmailChecked(emailChecked, emailStr);
    setEmailChecked();
  };

  return (
    <Stack.Item style={{ marginBottom: "10px" }}>
      {!displaySSO ? (
        <TextField
          label={t("email")}
          iconProps={{ iconName: "MailAttached" }}
          name={email.attributes.name}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              checkMail();
            }
          }}
          value={emailStr}
          placeholder={t("email-placeholder")}
          onChange={(
            _event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => {
            setEmailStr(newValue!);
          }}
        />
      ) : (
        <></>
      )}

      {email.messages?.length ? (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {email.messages
            .map((m) => (i18n.exists(String(m.id)) ? t(String(m.id)) : m.text))
            .join(";")}
        </MessageBar>
      ) : (
        <></>
      )}
      {emailChecked || email.attributes.value || signUp ? (
        <>
          {displaySSO && selectedSSO ? (
            <>
              <PrimaryButton
                key={selectedSSO.attributes.name}
                text={`${t("sign_in_sso")} ${selectedSSO.attributes.value}`}
                iconProps={{
                  iconName:
                    selectedSSO.attributes.value === "microsoft"
                      ? "WindowsLogo"
                      : "PermissionsSolid",
                }}
                style={{ width: "100%" }}
                type="submit"
                value={selectedSSO.attributes.value}
                name={selectedSSO.attributes.name}
                disabled={!enabled}
              />
            </>
          ) : (
            <>
              <TextField
                label={t("password")}
                type="password"
                name={password.attributes.name}
                canRevealPassword
                revealPasswordAriaLabel={t("show_password")}
                autoFocus={true}
                styles={{
                  field: {
                    "::-ms-reveal": {
                      display: "none",
                    },
                  },
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("pwSubmitBtn")?.click();
                  }
                }}
              />
              {password.messages?.length ? (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                  {password.messages
                    .map((m) => (i18n.exists(String(m.id)) ? t(String(m.id)) : m.text))
                    .join(";")}
                </MessageBar>
              ) : (
                <></>
              )}
              <PrimaryButton
                text={t(`${!signUp ? "sign_in" : "sign_up"}`)}
                type="submit"
                id="pwSubmitBtn"
                style={btnStyle}
                value={submit.attributes.value}
                name={submit.attributes.name}
                disabled={!enabled}
                onClick={() => {
                  const eventType: LoginSignupEventType = !signUp
                    ? "Sign In Via Mail"
                    : "Sign Up Via Mail";
                  Tracking.trackLoginSignupEvent(eventType);
                }}
              />
              {!signUp && (
                <Stack.Item style={{ marginTop: 10 }}>
                  <Link to={"/user-recovery"} target="_blank">
                    {t("forgot_password")}
                  </Link>
                </Stack.Item>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <DefaultButton text={t("continue")} style={btnStyle} onClick={checkMail} />
        </>
      )}
    </Stack.Item>
  );
};
