import { Checkbox, DirectionalHint, Stack, TooltipHost } from "@fluentui/react";

import { ColumnSelector } from "./ColumnSelector";
import React from "react";
import { t } from "i18next";
import {
  getCheckFromAnalysisConfig,
  getUpdateCheck,
} from "../../../helpers/quickAnalysis/analyisConfigHelpers";
import { allPiiCategories } from "../../../helpers/quickAnalysis/qualityChecks/pii";
import {
  QualityDimensionSettingsProps,
  SensitiveCheck,
  PiiCategory,
} from "../../../types/quickAnalysis";
import { defaultTooltipProps } from "./Settings.styles";

export const SensitiveSettings = ({
  analysisConfig,
  setAnalysisConfig,
  hideColumns,
}: QualityDimensionSettingsProps) => {
  const sensitiveCheck = getCheckFromAnalysisConfig(analysisConfig, "sensitive") as SensitiveCheck;
  const updateCheck = getUpdateCheck(analysisConfig, setAnalysisConfig, "sensitive");
  if (!sensitiveCheck) return null;
  const togglePiiCategory = (isChecked: boolean, piiCategory: PiiCategory) => {
    const newSensitiveCheck: SensitiveCheck = JSON.parse(JSON.stringify(sensitiveCheck));
    const newCategories = [...(sensitiveCheck.meta.categoryToCheck || [])].filter(
      (c) => c !== piiCategory
    );
    if (isChecked) newCategories.push(piiCategory);
    newSensitiveCheck.meta.categoryToCheck = newCategories;
    updateCheck(newSensitiveCheck);
  };

  return (
    <ColumnSelector check={sensitiveCheck} updateCheck={updateCheck} hideColumns={hideColumns}>
      <Stack style={{ cursor: "default" }}>
        <TooltipHost
          key={`tooltip_sensitive`}
          content={
            t("sensitive_column_tooltip") ||
            "Select which Personally Identifiable Information the tool should highlight in the data."
          }
          tooltipProps={defaultTooltipProps}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <p>{t("select_sensitive_types")}</p>
        </TooltipHost>
        <Stack tokens={{ childrenGap: 5 }}>
          <Checkbox
            label={t("select_all")}
            onChange={(_, isChecked?: boolean) => {
              const newSensitiveCheck = {
                ...sensitiveCheck,
                meta: { categoryToCheck: isChecked ? allPiiCategories : [] },
              };
              updateCheck(newSensitiveCheck);
            }}
            disabled={!sensitiveCheck.enabled}
            checked={allPiiCategories.reduce((acc: boolean, curr) => {
              return acc && sensitiveCheck.meta.categoryToCheck.includes(curr);
            }, true)}
          />
          {allPiiCategories.map((piiCategory, index) => {
            return (
              <Checkbox
                label={t(piiCategory) || piiCategory}
                onChange={(_, isChecked?: boolean) => {
                  togglePiiCategory(isChecked || false, piiCategory);
                }}
                key={index}
                disabled={!sensitiveCheck.enabled}
                checked={sensitiveCheck?.meta.categoryToCheck.includes(piiCategory)}
              />
            );
          })}
        </Stack>
      </Stack>
    </ColumnSelector>
  );
};
