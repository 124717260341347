import { DirectionalHint, Icon, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { t } from "i18next";
import { getInfoIconContainerProps, infoIssuesStackProps } from "./InfoIssues.styles";
import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { defaultTooltipProps } from "./settings/Settings.styles";

type InfoIssuesProps = {
  issues: QuickAnalysisIssue[];
};

export const InfoIssues = ({ issues }: InfoIssuesProps) => {
  const infoIssues = issues.filter((issue) => issue.severity === "info");
  const isDisabled = !infoIssues.length;
  const infoIcon = <Icon iconName="Info" />;
  const iconContainerStyles = getInfoIconContainerProps(isDisabled);

  return (
    <Stack {...infoIssuesStackProps}>
      {infoIssues.length > 0 ? (
        <>
          <TooltipHost
            content={t("info_tooltip")}
            tooltipProps={defaultTooltipProps}
            directionalHint={DirectionalHint.topLeftEdge}
          >
            <Stack {...iconContainerStyles}>
              <StackItem>{infoIssues.length}</StackItem>
              {infoIcon}
            </Stack>
          </TooltipHost>
        </>
      ) : (
        <Stack {...iconContainerStyles}>
          <StackItem>-</StackItem>
          {infoIcon}
        </Stack>
      )}
    </Stack>
  );
};
