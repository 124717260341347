import { FunctionComponent } from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { TaskSection } from "../../components/mission/tasks/TaskSection";
import { QualitySection } from "../../components/mission/quality/QualitySection";
import { HeaderSection } from "../../components/mission/header/HeaderSection";
import { DatasetSection } from "../../components/mission/datasets/DatasetSection";
import { IStackStyles, Separator, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { CommentWrapper } from "../../components/common/CommentWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "react-router-dom";
import { useGetCommentsQuery } from "../../api/comments";
const mainBoxStyles: IStackStyles = {
  root: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
  },
};

const innerBoxFirstContainer: IStackStyles = {
  root: {
    width: "45%",
    height: "auto",
  },
};

const innerBoxSecondContainer: IStackStyles = {
  root: {
    width: "45%",
    height: "auto",
  },
};

const TasksMainBoxStyles: IStackStyles = {
  root: {
    width: "95%",
    height: "auto",
    textAlign: "center",
    marginTop: "20px",
  },
};

const HeaderMainBoxStyles: IStackStyles = {
  root: {
    width: "95%",
    height: "auto",
  },
};

export const MissionDetail: FunctionComponent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { data } = useGetCommentsQuery(params.id ?? skipToken, {
    pollingInterval: 10000,
  }); // Added interval for comments api so that we have latest comments getting updated on frontend for consistency.);
  return (
    <TopContainer title={t("mission_detail")} backButton={true}>
      <main>
        <Stack styles={HeaderMainBoxStyles}>
          <HeaderSection />
        </Stack>
        <Stack styles={mainBoxStyles}>
          <Stack styles={innerBoxFirstContainer}>
            <DatasetSection />
          </Stack>
          <Stack styles={innerBoxSecondContainer}>
            <QualitySection />
          </Stack>
        </Stack>
        <Stack styles={TasksMainBoxStyles}>
          <TaskSection />
        </Stack>
        <Separator />
        <CommentWrapper
          comments={data?.comments || []}
          subjectId={params.id!}
          subjectType="mission"
          commentThreadId={data?.id}
        />
      </main>
    </TopContainer>
  );
};
