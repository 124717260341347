import { IStackStyles } from "@fluentui/react";

export const SettingsLabelStyles = {
  root: {
    display: "flex",
    fontSize: 16,
    alignItems: "center",
    gap: 12,
  },
};

export const SidebarContainerStyles: IStackStyles = {
  root: {
    position: "fixed",
    height: "100%",
    width: "20%",
    minWidth: "350px",
    top: "20px",
    right: "20px",
    padding: "16px",
    cursor: "pointer",
    border: "1px solid lightgrey",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    background: "white",
  },
};
