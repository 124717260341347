import missing from "./assets/missing_icon.svg";
import outlier from "./assets/outlier_icon.svg";
import sensitive from "./assets/sensitive_icon.svg";
import duplicates from "./assets/duplicates_icon.svg";
import inconsistent from "./assets/inconsistent_icon.svg";
import quality from "./assets/quality.svg";
import { createElement } from "react";
import { ReactComponent as questionMark } from "./assets/questionMark.svg";
import { ReactComponent as tickMark } from "./assets/tick-mark.svg";

export const MAIN_ICONS = {
  missingIcon: <img src={missing} alt="missing" width="21" height="21" />,
  outlierIcon: <img src={outlier} alt="outlier" width="21" height="21" />,
  sensitiveIcon: <img src={sensitive} alt="sensitive" width="21" height="21" />,
  duplicatesIcon: <img src={duplicates} alt="duplicates" width="21" height="21" />,
  inconsistentIcon: (
    <img src={inconsistent} alt="inconsistent" width="21" height="21" />
  ),
  tickMarkIcon: createElement(tickMark),
  questionMarkIcon: createElement(questionMark),
  qualityIcon: <img src={quality} alt="quality" width="16" height="17" />,
};
