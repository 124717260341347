import { QuickAnalysisIssueTypes } from "../../../types/quickAnalysis";

export const EMPTY_CELL_VALUES: string[] = [
  "",
  "-",
  " ",
  ".",
  '" "',
  '""',
  "N/A",
  "n/a",
  "NA",
  "na",
  "TBD",
  "tbd",
  "TBC",
  "tbc",
];

export const OVERALL_QUALITY = "Overall quality";

export const QUALITY_DIMENSION_TOOLTIPS: Record<QuickAnalysisIssueTypes, string> = {
  missing: "missing_tooltip",
  duplicate: "duplicate_tooltip",
  inconsistent: "inconsistent_tooltip",
  outlier: "outlier_tooltip",
  sensitive: "sensitive_tooltip",
};

export const ISSUE_TYPE_ICON_MAP: Map<string, string> = new Map([
  ["missing", "missingIcon"],
  ["inconsistent", "inconsistentIcon"],
  ["outlier", "outlierIcon"],
  ["duplicate", "duplicatesIcon"],
  ["sensitive", "sensitiveIcon"],
]);

export const ISSUE_TYPE_ICON_DISABLED_MAP: Map<QuickAnalysisIssueTypes, string> = new Map([
  ["missing", "missingIconDisabled"],
  ["inconsistent", "inconsistentIconDisabled"],
  ["outlier", "outlierIconDisabled"],
  ["duplicate", "duplicatesIconDisabled"],
  ["sensitive", "sensitiveIconDisabled"],
]);

export const allDimensions: QuickAnalysisIssueTypes[] = [
  "missing",
  "inconsistent",
  "outlier",
  "duplicate",
  "sensitive",
];

export const ISSUE_TYPE_HELP_CENTER_LINK: Map<QuickAnalysisIssueTypes, string> = new Map([
  [
    "missing",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295328734365-How-to-add-a-missing-value",
  ],
  [
    "inconsistent",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295329917725-How-to-improve-an-inconsistent-value",
  ],
  [
    "outlier",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295331452061-How-to-deal-with-outliers",
  ],
  [
    "duplicate",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295382572189-How-to-deal-with-duplicates",
  ],
  [
    "sensitive",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295387098653-How-to-deal-with-sensitive-data",
  ],
]);
