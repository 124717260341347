import { Checkbox, Icon, Stack, Text, Toggle } from "@fluentui/react";
import {
  CollapsedSettingsContainer,
  ExpandedSettingsContainer,
  lightGreyText,
} from "./Settings.styles";

import { ChevronIcon } from "./ChevronIcon";

import { t } from "i18next";
import { useState } from "react";
import { QuickAnalysisIssue, StandardCheck } from "../../../types/quickAnalysis";
import { capitalizeFirstLetter } from "../../../utils/strings";
import { markAllColumnsSelected } from "../../../helpers/quickAnalysis/analyisConfigHelpers";
import { ISSUE_TYPE_ICON_MAP } from "../../../helpers/quickAnalysis/qualityChecks/constants";
// this also works with react-router-native

export interface ColumnSelectorProps {
  check?: StandardCheck;
  updateCheck: (check: StandardCheck) => void;
  hideColumns: boolean;
  children?: JSX.Element;
  disableColumnSelector?: boolean;
}

export const ColumnSelector = ({
  check,
  updateCheck,
  hideColumns,
  disableColumnSelector,
  children,
}: ColumnSelectorProps) => {
  const [collapsed, setCollapsed] = useState(true);

  if (!check) return null;
  // TODO fix the inconsistency of sometimes naming it "custom" sometimes "custom_checks"
  const issueType: QuickAnalysisIssue["type"] = check?.name;
  const iconName: string | undefined = ISSUE_TYPE_ICON_MAP.get(issueType);
  return (
    <Stack styles={collapsed ? CollapsedSettingsContainer : ExpandedSettingsContainer}>
      <Stack horizontal>
        <Stack
          horizontal
          verticalAlign="center"
          grow
          tokens={{ childrenGap: 8 }}
          onClick={() => {
            if (children || !hideColumns) setCollapsed(!collapsed);
          }}
        >
          <Icon iconName={iconName} styles={{ root: { fontSize: "1.5em" } }} />
          <Text>{capitalizeFirstLetter(t(check.name))}</Text>
          <ChevronIcon collapsed={collapsed} hide={hideColumns && !children} />
          {disableColumnSelector && (
            <Stack
              style={{
                fontSize: "10px",
                flexShrink: 1,
                textAlign: "right",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t("only_for_vertical_tables")}
            </Stack>
          )}
        </Stack>
        <Toggle
          styles={{ root: { marginTop: "8px" } }}
          checked={check.enabled}
          onChange={(_, checked) => updateCheck({ ...check, enabled: checked!! })}
          disabled={disableColumnSelector}
        />
      </Stack>

      {!collapsed && children}
      {!collapsed && !hideColumns && (
        <Stack tokens={{ childrenGap: 12 }} styles={{ root: { margin: 8 } }}>
          <Text styles={lightGreyText}>{t("select_columns")}</Text>
          <Stack tokens={{ childrenGap: 4 }}>
            <Checkbox
              label={t("select_all")}
              onChange={(_, isChecked?: boolean) => {
                const newColumns = markAllColumnsSelected(check.columns, isChecked!!);
                updateCheck({ ...check, analyzeAllColumns: isChecked!!, columns: newColumns });
              }}
              key={"All"}
              disabled={!check.enabled}
              checked={check.analyzeAllColumns}
            />
            {check.columns.map((column) => {
              return (
                <Checkbox
                  label={column.name}
                  onChange={(_, isChecked?: boolean) => {
                    const newColumns = check.columns.map((c) =>
                      c.name !== column.name ? c : { ...c, selected: isChecked!! }
                    );
                    const analyzeAllColumns =
                      newColumns.filter(({ selected }) => selected).length === newColumns.length;
                    updateCheck({ ...check, columns: newColumns, analyzeAllColumns });
                  }}
                  key={`${check.name}::${column.name}::checkbox`}
                  disabled={!check.enabled}
                  checked={column.selected}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
