import { OutlierCheck, OutlierIssue, UploadDataContainer } from "../../../types/quickAnalysis";
import { mean, standardDeviation } from "simple-statistics";
import { EMPTY_CELL_VALUES } from "./constants";
import { isColumnSelected } from "./qualityUtils";

export const findOutlierIssues = (
  dataContainer: UploadDataContainer,
  outlierCheck: OutlierCheck
): OutlierIssue[] => {
  if (!outlierCheck.enabled) return [];
  const issues: OutlierIssue[] = [];
  for (let column = 0; column < dataContainer.data[0].length; column++) {
    if (!isColumnSelected(column, outlierCheck)) continue;

    const nonEmptyItems = dataContainer.data
      .map((row) => row[column])
      .filter((cell) => !EMPTY_CELL_VALUES.includes(cell.value.toString()));
    const numericValuesInColumn: number[] = [];
    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      const isCellNumeric = cell.type === "Integer" || cell.type === "Double";
      if (isCellNumeric && typeof cell.value === "number") numericValuesInColumn.push(cell.value);
    }
    // If most non-missing values are not ints or doubles don't do statistical analysis
    if (numericValuesInColumn.length <= nonEmptyItems.length / 2) continue;
    const values = outlierCheck.meta.considerZeros
      ? numericValuesInColumn
      : numericValuesInColumn.filter((value) => value !== 0);

    if (values.length === 0) continue;

    const MEAN = mean(values);
    const SD = standardDeviation(values);

    const cutOff = outlierCheck.meta.iqrCutoff;
    const max = MEAN + cutOff * SD;
    const min = MEAN - cutOff * SD;

    const warningMax = MEAN + (cutOff + 1) * SD;
    const warningMin = MEAN - (cutOff + 1) * SD;

    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      if (cell.value === 0 && !outlierCheck.meta.considerZeros) continue;
      const isCellNumeric = cell.type === "Integer" || cell.type === "Double";
      if (isCellNumeric && typeof cell.value === "number") {
        if (cell.value > max || cell.value < min) {
          const severity = cell.value > warningMax || cell.value < warningMin ? "warning" : "info";
          issues.push({
            row: cell.row,
            column: cell.column,
            type: "outlier",
            comment: severity === "warning" ? "extreme_outlier_comment" : "outlier_comment",
            severity,
          });
        }
      }
    }
  }
  return [...issues, ...findWrongSignIssues(dataContainer, outlierCheck)];
};

export const findWrongSignIssues = (
  dataContainer: UploadDataContainer,
  outlierCheck: OutlierCheck
): OutlierIssue[] => {
  if (!outlierCheck.enabled || !outlierCheck.meta.checkSign) return [];
  const issues: OutlierIssue[] = [];
  for (let column = 0; column < dataContainer.data[0].length; column++) {
    if (!isColumnSelected(column, outlierCheck)) continue;
    let positiveCount = 0;
    let negativeCount = 0;
    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      const isCellNumericAndNotZero =
        (cell.type === "Integer" || cell.type === "Double") && cell.value;
      if (isCellNumericAndNotZero && typeof cell.value === "number") {
        cell.value > 0 ? positiveCount++ : negativeCount++;
      }
    }
    if (!(positiveCount * 10 > negativeCount || negativeCount * 10 > positiveCount)) {
      // If not 10x more positive or negative values don't create issues
      continue;
    }

    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      const isCellNumeric = cell.type === "Integer" || cell.type === "Double";
      if (isCellNumeric && typeof cell.value === "number") {
        if (
          (positiveCount > negativeCount && cell.value < 0) ||
          (positiveCount < negativeCount && cell.value > 0)
        ) {
          issues.push({
            row: cell.row,
            column: cell.column,
            type: "outlier",
            comment: "different_sign_comment",
            severity: "info",
          });
        }
      }
    }
  }
  return issues;
};
