import { Stack, DefaultButton, IconButton } from "@fluentui/react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  selectLatestQuickAnalysisIssues,
  setActiveFilter,
  setQuickAnalysisStatus,
  setSidebarView,
} from "../../../store/slices/quickAnalysis";
import { clearAllFiles } from "../../../store/slices/upload";
import { t } from "i18next";
import { OverallQuality } from "./OverallQuality";
import { QualityDimension } from "./QualityDimension";
import { SidebarContainerStyles } from "./AnalysisSidebar.styles";
import { allDimensions } from "../../../helpers/quickAnalysis/qualityChecks/constants";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ArrowKey,
} from "../../../helpers/quickAnalysis/dimensionUtil";
import { useEffect } from "react";
import { handleArrowPress } from "../../../store/slices/issueNavigator";

export const QualitySummary = () => {
  const issues = useAppSelector(selectLatestQuickAnalysisIssues);
  const dispatch = useAppDispatch();

  const keydownListener = (event: Event) => {
    if (event instanceof KeyboardEvent) {
      if ([ARROW_UP, ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT].includes(event.key as ArrowKey)) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(handleArrowPress(event.key as ArrowKey));
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, []);

  return (
    <Stack tokens={{ childrenGap: 4 }} styles={SidebarContainerStyles}>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultButton
          iconProps={{ iconName: "cancel" }}
          styles={{ root: { border: "none" } }}
          onClick={() => {
            dispatch(setActiveFilter(undefined));
            dispatch(setQuickAnalysisStatus("selectFile"));
            dispatch(clearAllFiles());
          }}
        >
          {t("end_analysis")}
        </DefaultButton>
        <IconButton
          iconProps={{ iconName: "settings" }}
          onClick={() => dispatch(setSidebarView("settings"))}
        />
      </Stack>
      <OverallQuality issues={issues} />
      <Stack tokens={{ childrenGap: 4 }}>
        {allDimensions.map((qualityDimension) => {
          return <QualityDimension key={qualityDimension} qualityDimension={qualityDimension} />;
        })}
      </Stack>
    </Stack>
  );
};
