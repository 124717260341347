import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "../store";
import {
  QuickAnalysisIssue,
  QuickAnalysisIssueTypes,
  UploadDataContainer,
} from "../../types/quickAnalysis";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ArrowKey,
} from "../../helpers/quickAnalysis/dimensionUtil";
import { allDimensions } from "../../helpers/quickAnalysis/qualityChecks/constants";

type IssueNavigatorMeta = {
  type: QuickAnalysisIssueTypes;
  selectedCustomCheck?: string;
};
export type DimensionIssues = {
  meta: IssueNavigatorMeta;
  issues: QuickAnalysisIssue[];
};
export type IssueNavigator = {
  dimensionIndex: number;
  issueIndex: number;
  fileName: string;
  meta?: IssueNavigatorMeta;
};

const issueNavigatorAdapter = createEntityAdapter<IssueNavigator>({
  selectId: (issueNavigator) => issueNavigator.fileName,
});

export const handleArrowPress = createAsyncThunk<
  IssueNavigator | undefined,
  ArrowKey,
  { state: RootState }
>("issueNavigator/handleArrowPress", async (arrowPressed, { getState }) => {
  const state: RootState = getState();

  const latestUpload = selectLatestUpload(state);
  const fileName = latestUpload?.fileName;
  if (!fileName) return undefined;
  const dimensionsWithIssues = selectCurrentDimensionIssues(state);

  const currentIssueNavigator = selectById(state, latestUpload?.fileName || "");
  if (!currentIssueNavigator) {
    return {
      dimensionIndex: 0,
      issueIndex: 0,
      fileName,
      meta: { type: dimensionsWithIssues[0].meta.type },
    };
  }

  let newIssueNavigator: IssueNavigator | undefined = undefined;
  const { dimensionIndex, issueIndex, meta } = currentIssueNavigator;
  if (arrowPressed === ARROW_DOWN) {
    if (dimensionsWithIssues.length === 1) return currentIssueNavigator;
    const downDimensionIndex =
      dimensionIndex === dimensionsWithIssues.length
        ? 0
        : (dimensionIndex + 1) % dimensionsWithIssues.length;
    newIssueNavigator = {
      fileName,
      issueIndex: 0,
      dimensionIndex: downDimensionIndex,
      meta: { type: dimensionsWithIssues[downDimensionIndex].meta.type },
    };
  } else if (arrowPressed === ARROW_UP) {
    if (dimensionsWithIssues.length === 1) return currentIssueNavigator;
    // x + N % N = x
    // JS Modulo allows for negatives so we add N to always be a positive number
    const upDimensionIndex =
      (dimensionIndex - 1 + dimensionsWithIssues.length) % dimensionsWithIssues.length;
    newIssueNavigator = {
      fileName,
      issueIndex: 0,
      dimensionIndex: upDimensionIndex,
      meta: { type: dimensionsWithIssues[upDimensionIndex].meta.type },
    };
  } else if (arrowPressed === ARROW_RIGHT) {
    newIssueNavigator = {
      fileName,
      issueIndex: (issueIndex + 1) % dimensionsWithIssues[dimensionIndex].issues.length,
      dimensionIndex,
      meta: meta ? { type: meta.type } : undefined,
    };
  } else if (arrowPressed === ARROW_LEFT) {
    const issuesInDimension = dimensionsWithIssues[dimensionIndex].issues.length;
    newIssueNavigator = {
      fileName,
      issueIndex: (issueIndex - 1 + issuesInDimension) % issuesInDimension,
      dimensionIndex,
      meta: meta ? { type: meta.type } : undefined,
    };
  } else {
    return currentIssueNavigator;
  }
  return newIssueNavigator;
});

type SelectIssueInSheetPayload = {
  issue: QuickAnalysisIssue;
};
export const handleSelectIssueInSheet = createAsyncThunk<
  IssueNavigator | undefined,
  SelectIssueInSheetPayload,
  { state: RootState }
>("issueNavigator/handleSelectIssueInSheet", async (payload, { getState }) => {
  const state: RootState = getState();
  const fileName = selectLatestUpload(state)?.fileName;
  const dimensionIssues = selectCurrentDimensionIssues(state);
  const dimensionIndex = dimensionIssues.findIndex(
    (dimensionIssue) => dimensionIssue.meta.type === payload.issue.type
  );
  if (dimensionIndex === -1 || !fileName) return undefined;
  const issueIndex = dimensionIssues[dimensionIndex].issues.findIndex(
    (issue) => issue.id === payload.issue.id
  );
  if (issueIndex === -1) return undefined;
  const issueNavigator = {
    fileName,
    dimensionIndex,
    issueIndex,
    meta: {
      type: payload.issue.type,
    },
  };

  return issueNavigator;
});

export const handleClickOnQualityDimension = createAsyncThunk<
  IssueNavigator | undefined,
  QuickAnalysisIssueTypes,
  { state: RootState }
>("issueNavigator/handleClickOnQualityDimension", async (issueType, { getState }) => {
  const state: RootState = getState();
  const fileName = selectLatestUpload(state)?.fileName;
  const dimensionIssues = selectCurrentDimensionIssues(state);
  const activeFilter = state.quickAnalysis.activeFilter;
  if (activeFilter === issueType) return undefined;
  const dimensionIndex = dimensionIssues.findIndex(
    (structuredIssue) => structuredIssue.meta.type === issueType
  );
  if (dimensionIndex === -1 || !fileName) return undefined;
  const issueNavigator = {
    fileName,
    dimensionIndex,
    issueIndex: 0,
    meta: { type: issueType },
  };
  return issueNavigator;
});

export const issueNavigatorSlice = createSlice({
  name: "issueNavigator",
  initialState: issueNavigatorAdapter.getInitialState(),
  reducers: {
    clearIssueNavigator: (state, { payload }: PayloadAction<string>) => {
      issueNavigatorAdapter.removeOne(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleArrowPress.fulfilled, (state, { payload }) => {
      if (payload) issueNavigatorAdapter.upsertOne(state, payload);
    });
    builder.addCase(handleSelectIssueInSheet.fulfilled, (state, { payload }) => {
      if (!payload) return;
      issueNavigatorAdapter.upsertOne(state, payload);
    });
    builder.addCase(handleClickOnQualityDimension.fulfilled, (state, { payload }) => {
      if (!payload) return;
      issueNavigatorAdapter.upsertOne(state, payload);
    });
  },
});

const selectLatestUpload = createSelector(
  (state: RootState) => state.upload,
  (uploadSlice): UploadDataContainer | undefined => {
    if (uploadSlice.ids.length === 0) return undefined;
    const latestId = uploadSlice.ids[uploadSlice.ids.length - 1];
    return uploadSlice.entities[latestId];
  }
);

const selectIssueNavigatorSlice = (state: RootState) => state.issueNavigator;
const selectCurrentIssueNavigator = createSelector(
  selectLatestUpload,
  selectIssueNavigatorSlice,
  (latestUpload, issueNavigatorSlice) => {
    const fileName = latestUpload?.fileName;
    if (!fileName) return undefined;
    return issueNavigatorSlice.entities[fileName];
  }
);

const { selectById } = issueNavigatorAdapter.getSelectors<RootState>(
  (state) => state.issueNavigator
);
export const { clearIssueNavigator } = issueNavigatorSlice.actions;
export const selectIssueNavigatorById = selectById;

export const selectAnalysisConfigSlice = (state: RootState) => state.analysisConfig;

const selectCurrentIssues = (state: RootState) => state.quickAnalysis.issues;
export const selectCurrentDimensionIssues = createSelector(
  selectCurrentIssues,
  (issues: QuickAnalysisIssue[]) => {
    return getDimensionIssues(issues);
  }
);

export const selectCurrentDimensionIssue = createSelector(
  selectCurrentDimensionIssues,
  selectCurrentIssueNavigator,
  (dimensionIssues, issueNavigator) => {
    if (!dimensionIssues || !issueNavigator) return undefined;
    return dimensionIssues[issueNavigator.dimensionIndex];
  }
);

export const selectHighlightedIssue = createSelector(
  selectCurrentDimensionIssues,
  selectCurrentIssueNavigator,
  (dimensionIssues, issueNavigator): QuickAnalysisIssue | undefined => {
    if (!dimensionIssues || !issueNavigator) return undefined;
    return dimensionIssues[issueNavigator.dimensionIndex]?.issues[issueNavigator.issueIndex];
  }
);

export const getDimensionIssues = (allIssues: QuickAnalysisIssue[]): DimensionIssues[] => {
  return allDimensions
    .map((dimension) => {
      const issues: QuickAnalysisIssue[] = allIssues.filter((issue) => issue.type === dimension);
      return {
        meta: {
          type: dimension,
        },
        issues,
      };
    })
    .filter((currentDimensionIssue) => !!currentDimensionIssue.issues.length);
};
