import { Pivot, PivotItem } from "@fluentui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TopContainer } from "../../components/common/TopContainer";
import { ColleaguesGroupsDetail } from "../../components/group/ColleaguesGroupsDetail";

export const CompanyInfo: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <TopContainer title={t("company_info")}>
      <main>
        <Pivot linkSize="large">
          <PivotItem headerText={t("company_info")}>
            {/* <Label>{t("company_info")}</Label> */}
          </PivotItem>
          <PivotItem headerText={t("colleagues_and_groups")}>
            <ColleaguesGroupsDetail />
          </PivotItem>
        </Pivot>
      </main>
    </TopContainer>
  );
};
