import { Text } from "@fluentui/react";
import { useAppSelector } from "../../store/hooks";
import { selectLatestQuickAnalysisStatus } from "../../store/slices/quickAnalysis";
import { Analyzing } from "./Analyzing";
import { FileUploader } from "./FileUploader";
import { AnalysisResult } from "./AnalysisResult";
import { TopContainer } from "../../components/common/TopContainer";
import { t } from "i18next";

export const QuickAnalysis = (): JSX.Element => {
  const status = useAppSelector(selectLatestQuickAnalysisStatus);

  return (
    <TopContainer title={t("quick_analysis")}>
      {status === "selectFile" && <FileUploader />}
      {status === "analyzing" && <Analyzing />}
      {status === "results" && <AnalysisResult />}
      {!status && <Text>RESULTS GO HERE</Text>}
    </TopContainer>
  );
};
