import { Icon, Stack, StackItem, TooltipHost } from "@fluentui/react";
import { getIssueIcon, highlightedContainerStyles } from "./IssueExplanation.styles";
import { t } from "i18next";
import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { defaultTooltipProps } from "./settings/Settings.styles";
import { getInconsistentIssueComment } from "../../helpers/quickAnalysis/getIssueComments";
import { ISSUE_TYPE_HELP_CENTER_LINK } from "../../helpers/quickAnalysis/qualityChecks/constants";

const warningIcon = <Icon iconName="Warning" styles={getIssueIcon("warning")} />;
const infoIcon = <Icon iconName="Info" styles={getIssueIcon("info")}></Icon>;
const mytooltipprops = JSON.parse(JSON.stringify(defaultTooltipProps));

type IssueExplanationProps = {
  issue: QuickAnalysisIssue;
};
export const IssueExplanation = ({ issue }: IssueExplanationProps) => {
  let explanation: string;
  if (issue.type === "inconsistent") {
    explanation = getInconsistentIssueComment(issue.comment, t);
  } else {
    explanation = issue.type === "sensitive" ? t(issue.category) : t(issue.comment);
  }
  const helpCenterLink: string =
    ISSUE_TYPE_HELP_CENTER_LINK.get(issue.type) || "https://dqchelp.zendesk.com";

  return (
    <Stack styles={highlightedContainerStyles}>
      <Stack
        horizontal
        verticalAlign={"center"}
        styles={{
          root: { paddingRight: 10 },
        }}
      >
        <Stack horizontal styles={{ root: { width: 20 } }}>
          {issue.severity === "warning" ? warningIcon : infoIcon}
        </Stack>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" grow>
          <StackItem>{explanation}</StackItem>
          <TooltipHost content={t("issue_explanation_tooltip")} tooltipProps={mytooltipprops}>
            <a
              href={helpCenterLink}
              target="_blank"
              title={t("issue_explanation_tooltip")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              rel="noreferrer"
            >
              <Icon iconName="Lightbulb" />
            </a>
          </TooltipHost>
        </Stack>
      </Stack>
    </Stack>
  );
};
