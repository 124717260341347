import {
  UploadDQCDataType,
  PiiCategory,
  PiiIssue,
  SensitiveCheck,
  UploadDataContainer,
} from "../../../types/quickAnalysis";
import { checkStringForPIIName } from "./nameDict";
import {
  emailAddress,
  usSocialSecurityNumber,
  passportDE,
  creditCardNumber,
  phoneNumber,
  ipAddress,
  IBAN,
} from "./piiRegexPatterns";
import { isColumnSelected } from "./qualityUtils";

const regexLibrary: Map<PiiCategory, RegExp> = new Map([
  ["Email address", emailAddress],
  ["Social security number", usSocialSecurityNumber],
  ["Passport number", passportDE],
  ["Credit card", creditCardNumber],
  ["Phone number", phoneNumber],
  ["IP address", ipAddress],
  ["IBAN", IBAN],
]);

export const allPiiCategories: PiiCategory[] = [
  "Email address",
  "Social security number",
  "Passport number",
  "Credit card",
  "Phone number",
  "IP address",
  "IBAN",
  "Name",
];

export const findPiiIssues = (
  dataContainer: UploadDataContainer,
  sensitiveCheck: SensitiveCheck
): PiiIssue[] => {
  // TODO read this from config
  if (!sensitiveCheck.enabled) return [];
  const piiRegexes = sensitiveCheck.meta.categoryToCheck
    .filter((category: PiiCategory) => regexLibrary.has(category))
    .map((category: PiiCategory) => regexLibrary.get(category));

  const issues: PiiIssue[] = [];
  const shouldCheckForNames = sensitiveCheck.meta.categoryToCheck.includes("Name");
  for (let column = 0; column < dataContainer.data[0].length; column++) {
    // TODO how we can skip date formats...
    if (!isColumnSelected(column, sensitiveCheck)) continue;

    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      piiRegexes.forEach((regex: RegExp | undefined, i) => {
        const piiIssue = regex
          ? detector(cell, regex, sensitiveCheck.meta.categoryToCheck[i])
          : null;
        if (piiIssue) issues.push(piiIssue);
      });
      if (shouldCheckForNames) {
        const piiIssue = checkStringForPIIName(cell, cell.value.toString());
        if (piiIssue) issues.push(piiIssue);
      }
    }
  }
  return issues;
};

const detector = (
  cell: UploadDQCDataType,
  regex: RegExp,
  category: PiiCategory
): PiiIssue | null => {
  if (category === "Credit card") {
    return detectCreditCardNumber(cell);
  }
  const cellValue =
    category === "IBAN" ? removeAllSeparators(cell.value.toString()) : cell.value.toString();
  if (regex.test(cellValue)) {
    const severity = category === "IBAN" || category === "Email address" ? "warning" : "info";
    return {
      row: cell.row,
      column: cell.column,
      category,
      type: "sensitive",
      comment: "sensitive_comment",
      severity,
    };
  }
  return null;
};

export const detectCreditCardNumber = (cell: UploadDQCDataType): PiiIssue | null => {
  const numericParts = getNumericParts(cell.value.toString());
  if (!numericParts) return null;
  const formattedParts = numericParts.map((part) => removeAllSeparators(part));
  for (let i = 0; i < formattedParts.length; i++) {
    if (creditCardNumber.test(formattedParts[i]) && !isDecimalRegex.test(formattedParts[i]))
      return {
        row: cell.row,
        column: cell.column,
        category: "Credit card",
        type: "sensitive",
        comment: "sensitive_comment",
        severity: "info",
      };
  }
  return null;
};

export const getNumericParts = (cellValue: string): null | string[] => {
  const parts = cellValue.match(numericRegex);
  return parts;
};

export const removeAllSeparators = (cellValue: string) => {
  return cellValue.replace(/[-_/ \\|]/g, "");
};

const numericRegex = new RegExp(/([0-9]+[-_/ \\\|\.]?)+/, "g");
const isDecimalRegex = new RegExp(/^[0-9]+\.[0-9]+$/);
