import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  DefaultPalette,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Spinner,
  Stack,
  ThemeProvider,
  registerIcons,
} from "@fluentui/react";
import React, { useEffect } from "react";

import { getFlowIdOrAcquire, useWhoAmIQuery, useGetMyProfileQuery } from "./api/profile";
import { AllMissions } from "./routes/mission/all-missions";
import { Surveys } from "./routes/surveys/surveys";
import { BulkUpload } from "./routes/upload/BulkUpload";
import { Cockpit } from "./routes/cockpit/cockpit";
import { CompanyInfo } from "./routes/company-info/company-info";
import { Connections } from "./routes/connections/connection";
import { ErrorView } from "./routes/users/error";
import { Interactions } from "./routes/interactions/interaction";
import { IssueView } from "./routes/issues/issue-view";
import { LearningJourneys } from "./routes/learning-journeys/learning-journeys";
import { ManageLicenses } from "./routes/licenses/manage-licenses";
import { MissionDetail } from "./routes/mission/mission-detail";
import { NewMission } from "./routes/mission/new-mission";
import { Overview } from "./routes/overview/overview";
import { Plugins } from "./routes/plugins/plugins";
import { Profile } from "./routes/users/profile";
import { QUISidebar } from "./Sidebar";
import { Quality } from "./routes/quality/quality";
import { Quickstart } from "./routes/quickstart/quickstart";
import { Remarks } from "./routes/remarks/remarks";
import { UserRecovery } from "./routes/users/userRecovery";
import { UserVerifcation } from "./routes/users/userVerification";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { setIdentityId } from "./store/slices/app";
import { useAppDispatch } from "./store/hooks";
import { SurveyDetail } from "./routes/surveys/survey";
import { SurveyForm } from "./routes/surveys/form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Assets } from "./routes/cockpit/assets/assets";
import { Asset } from "./routes/cockpit/assets/asset";
import { UseCase } from "./routes/cockpit/use-cases/use-case";
import { UseCases } from "./routes/cockpit/use-cases/use-cases";
import { useGetInstallationsQuery } from "./api/installation";
import { MsalProvider } from "@azure/msal-react";
import { dqcTheme } from "./helpers/commonStyles";
import { ColabCore } from "./routes/colab/colab-core";
import { msalInstanceProvider } from "./components/colab/powerbi-embedding";
import { Colab } from "./routes/colab/colab";
import { SignIn } from "./routes/users/signin";
import { SignUp } from "./routes/users/signup";
import { isOffice } from "./api/dqcQuery";
import { QuickAnalysis } from "./routes/quick-analysis/QuickAnalysis";
import { MAIN_ICONS } from "./Icons";

initializeIcons(/* optional base url */);
registerIcons({
  icons: MAIN_ICONS,
});
const stackItemStyles: IStackItemStyles = {
  root: {
    background: dqcTheme.palette.neutralLight,
    color: dqcTheme.palette.black,
    display: "flex",
    height: "auto",
    justifyContent: "center",
    overflow: "hidden",
  },
};

const innerStackTokens: IStackTokens = {
  childrenGap: 0,
  padding: 0,
};

const stackStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.neutralLight,
    position: "absolute",
    top: "0px",
    left: "0px",
    bottom: "0px",
    right: "0px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
};

const innerStackItemStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.white,
    overflow: "auto",
    WebkitOverflowScrolling: "none",
    scrollbarWidth: "none",
    MsOverflowStyle: "none",
    borderLeft: "0px solid #FCFCFC",
  },
};

const stackItemStylesNav: IStackItemStyles = {
  root: {
    borderRight: "1px solid #C3C3C3",
  },
};

const loadDefaultStart = (
  loading: boolean,
  withInstallation: boolean,
  canAccessPlattform: boolean = false
) => {
  if (loading) {
    return (
      <>
        <Spinner label="Loading" labelPosition="right" />
      </>
    );
  }
  return canAccessPlattform ? (
    <Quickstart hasInstallation={withInstallation} />
  ) : (
    <Navigate to="/profile" />
  );
};

export const App: React.FunctionComponent = () => {
  const surveyForm = window.location.href.match(/surveys\/form/) !== null;
  const embedding = window.location.href.match(/colab/) !== null;
  const dispatch = useAppDispatch();
  const flowId = new URLSearchParams(window.location.search).get("flow");
  const session = useWhoAmIQuery(!surveyForm ? {} : skipToken);
  const profileRequest = useGetMyProfileQuery(!surveyForm ? {} : skipToken);
  const installationData = useGetInstallationsQuery();
  const partOfInstallation = installationData.isSuccess && installationData !== undefined;
  const returnTo = isOffice() ? localStorage.getItem("addinUrl") : localStorage.getItem("returnTo");

  useEffect(() => {
    if (session.currentData?.active && returnTo && !flowId) {
      localStorage.removeItem("returnTo");
      window.location.replace(returnTo);
      return;
    }
    if (session.currentData?.active) {
      dispatch(setIdentityId(session.currentData?.identity?.id!));
      return;
    }
    if (session.isError || session.data?.error) {
      getFlowIdOrAcquire();
      return;
    }
  }, [session, dispatch, returnTo, flowId]);

  return (
    <ThemeProvider theme={dqcTheme}>
      <BrowserRouter>
        <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
          <Stack.Item
            disableShrink
            styles={{
              ...stackItemStylesNav,
              root: { borderRight: 0 },
            }}
            hidden={session.isError || !session.currentData?.active || surveyForm || embedding}
          >
            {profileRequest.data?.accessPlatform && (
              <QUISidebar
                userName={session.currentData?.identity?.traits.email!}
                hasSession={session.currentData?.active}
                hasInstallation={partOfInstallation}
                isLoading={session.isLoading || profileRequest.isLoading}
              />
            )}
          </Stack.Item>
          <Stack.Item grow styles={stackItemStyles}>
            <Stack grow styles={innerStackItemStyles}>
              <Routes>
                <Route
                  path="/"
                  element={loadDefaultStart(
                    installationData.isLoading,
                    partOfInstallation,
                    profileRequest.data?.accessPlatform
                  )}
                />
                <Route path="dashboard" element={<Overview />} />
                <Route path="dashboard/quality" element={<Quality />} />
                <Route path="dashboard/interactions" element={<Interactions />} />
                <Route path="surveys" element={<Surveys />} />
                <Route path="surveys/:id" element={<SurveyDetail />} />
                <Route path="surveys/form/:id" element={<SurveyForm />} />
                <Route path="missions" element={<AllMissions />} />
                <Route path="cockpit" element={<Cockpit />} />
                <Route path="cockpit/use-cases" element={<UseCases />} />
                <Route path="cockpit/use-cases/:id" element={<UseCase />} />
                <Route path="cockpit/assets" element={<Assets />} />
                <Route path="cockpit/assets/:id" element={<Asset />} />
                <Route path="quick-analysis" element={<QuickAnalysis />} />
                <Route path="missions/upload" element={<BulkUpload />} />
                <Route path="missions/:id" element={<MissionDetail />} />
                <Route path="missions/new" element={<NewMission />} />
                <Route
                  path="/missions/:missionId/dataset/:datasetId/issues/:qualityDimension"
                  element={<IssueView />}
                />
                <Route
                  path="/missions/:missionId/dataset/:datasetId/issues"
                  element={<IssueView />}
                />
                <Route path="connections" element={<Connections />} />
                <Route path="plugins" element={<Plugins />} />
                <Route path="learning-journeys" element={<LearningJourneys />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="remarks" element={<Remarks />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/error" element={<ErrorView />} />
                <Route path="/user-verification" element={<UserVerifcation />} />
                <Route path="/user-recovery" element={<UserRecovery />} />
                <Route path="/company" element={<CompanyInfo />} />
                <Route path="/licenses" element={<ManageLicenses />} />
              </Routes>
              <MsalProvider instance={msalInstanceProvider()}>
                <Routes>
                  <Route path="/colab/powerbi/report/:reportid" element={<ColabCore />} />
                  <Route path="/colab/*" element={<Colab />} />
                </Routes>
              </MsalProvider>
            </Stack>
          </Stack.Item>
        </Stack>
      </BrowserRouter>
    </ThemeProvider>
  );
};
