import {
  getCheckFromAnalysisConfig,
  getUpdateCheck,
} from "../../../helpers/quickAnalysis/analyisConfigHelpers";
import { QualityDimensionSettingsProps } from "../../../types/quickAnalysis";
import { ColumnSelector } from "./ColumnSelector";

export const MissingSettings = ({
  analysisConfig,
  setAnalysisConfig,
  hideColumns,
}: QualityDimensionSettingsProps) => {
  const missingCheck = getCheckFromAnalysisConfig(analysisConfig, "missing");
  const updateCheck = getUpdateCheck(analysisConfig, setAnalysisConfig, "missing");
  return (
    <ColumnSelector check={missingCheck} updateCheck={updateCheck} hideColumns={hideColumns} />
  );
};
