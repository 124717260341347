import { DirectionalHint, Slider, Stack, StackItem, TooltipHost } from "@fluentui/react";

import { ColumnSelector } from "./ColumnSelector";
import React from "react";
import { t } from "i18next";
import {
  getCheckFromAnalysisConfig,
  getUpdateCheck,
} from "../../../helpers/quickAnalysis/analyisConfigHelpers";
import { DuplicateSettingsProps, DuplicateCheck } from "../../../types/quickAnalysis";
import { defaultTooltipProps, sliderProps } from "./Settings.styles";

export const DuplicateSettings = ({
  analysisConfig,
  setAnalysisConfig,
  hideColumns,
  disableColumnSelector,
}: DuplicateSettingsProps) => {
  const duplicateCheck = getCheckFromAnalysisConfig(analysisConfig, "duplicate") as DuplicateCheck;
  const updateCheck = getUpdateCheck(analysisConfig, setAnalysisConfig, "duplicate");
  if (!duplicateCheck) return null;
  const sliderOnChange = (value: number) => {
    const newDuplicateCheck: DuplicateCheck = JSON.parse(JSON.stringify(duplicateCheck));
    newDuplicateCheck.meta.threshold = value / 100;
    updateCheck(newDuplicateCheck);
  };
  const sliderValueFormat = (value: number) => `${value}%`;

  return (
    <ColumnSelector
      check={duplicateCheck}
      updateCheck={updateCheck}
      hideColumns={hideColumns}
      disableColumnSelector={disableColumnSelector}
    >
      <Stack style={{ cursor: "default" }}>
        <TooltipHost
          key={`tooltip_duplicate`}
          content="% of characters that can be different for two rows to still be considered as duplicates"
          tooltipProps={defaultTooltipProps}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <StackItem>{t("check_duplicates")}</StackItem>
        </TooltipHost>
        <Stack horizontal {...sliderProps}>
          <Stack style={{ fontSize: "12px" }}>{t("exact")}</Stack>
          <Slider
            styles={{ root: { marginTop: "14px", width: "80%" } }}
            key={"duplicate_slider"}
            max={20}
            min={0}
            showValue={false}
            onChange={sliderOnChange}
            defaultValue={duplicateCheck.meta.threshold * 100}
            disabled={!duplicateCheck.enabled}
            valueFormat={sliderValueFormat}
          />
          <Stack style={{ fontSize: "12px" }}>{t("fuzzy")}</Stack>
        </Stack>
      </Stack>
    </ColumnSelector>
  );
};
