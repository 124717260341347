import { ApiSettings } from "../types/connection";

export const defaultTextfieldData: Record<string, ApiSettings> = {
  ms_teams_connector: {
    tenant_id: "",
    client_id: "",
    client_secret: "",
  },
  sharepoint_connector: {
    tenant_id: "",
    client_id: "",
    client_secret: "",
  },
  ms_teams_bot_connector: {
    app_id: "",
    app_password: "",
  },
  powerbi_connector: {
    client_id: "",
  },
  psql_connector: {
    conn_string: "",
  },
  snowflake_connector: {
    conn_string: "",
  },
};
