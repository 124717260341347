import { configureStore } from "@reduxjs/toolkit";
import { dqcApi } from "../api/dqcQuery";
import { missionDetailSlice } from "./slices/missionSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { qualitySummarySlice } from "./slices/qualitySummarySlice";
import { appSlice } from "./slices/app";
import { profileApi } from "../api/profile";
import { cockpitSlice } from "./slices/cockpitSlice";
import { remarkSlice } from "./slices/remarkSlice";
import { microsoftGraphApi } from "../api/users";
import { uploadSlice } from "./slices/upload";
import { quickAnalysisSlice } from "./slices/quickAnalysis";
import { analysisConfigSlice } from "./slices/analysisConfig";
import { issueNavigatorSlice } from "./slices/issueNavigator";

const store = configureStore({
  reducer: {
    missionDetail: missionDetailSlice.reducer,
    //missionList: missionListSlice.reducer,
    qualitySummary: qualitySummarySlice.reducer,
    [dqcApi.reducerPath]: dqcApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    app: appSlice.reducer,
    cockpit: cockpitSlice.reducer,
    remark: remarkSlice.reducer,
    microsoftGraphApi: microsoftGraphApi.reducer,
    upload: uploadSlice.reducer,
    quickAnalysis: quickAnalysisSlice.reducer,
    analysisConfig: analysisConfigSlice.reducer,
    issueNavigator: issueNavigatorSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dqcApi.middleware),
});
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
