export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toProperCase = (word: string) => {
  word = word.replace(/_/g, " ");
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const getMostFrequent = (strings: (undefined | string)[]): string | undefined => {
  const occurrences = strings.reduce(
    (frequencyMap: { [key: string]: number } | undefined, s: undefined | string) => {
      if (s !== undefined && frequencyMap !== undefined) {
        frequencyMap[s] ? ++frequencyMap[s] : (frequencyMap[s] = 1);
        return frequencyMap;
      } else {
        return undefined;
      }
    },
    {}
  );

  if (occurrences)
    return Object.entries(occurrences).reduce(
      ([accFormat, accFrequency], [format, frequency]) => {
        if (accFrequency && accFrequency >= frequency) return [accFormat, accFrequency];
        return [format, frequency];
      },
      ["", 0]
    )[0];
  else {
    return undefined;
  }
};

export type MostFrequentStringArray = {
  mostFrequent: string;
  frequency: number;
};
export const getMostFrequentString = (strings: string[]): MostFrequentStringArray => {
  const occurrences = strings.reduce((frequencyMap: { [key: string]: number }, s: string) => {
    frequencyMap[s] ? ++frequencyMap[s] : (frequencyMap[s] = 1);
    return frequencyMap;
  }, {});

  const [mostFrequent, frequency] = Object.entries(occurrences).reduce(
    ([accFormat, accFrequency], [format, frequency]) => {
      if (accFrequency >= frequency) return [accFormat, accFrequency];
      return [format, frequency];
    },
    ["", 0]
  );
  return {
    mostFrequent,
    frequency,
  };
};

/**
 * Lowercases the input string, removes:
 * any multiple spaces
 * leading/trailing spaces
 * all punctuation
 *
 * @param s
 * @returns
 */
export const normalizeString = (s: string): string => {
  s = s.toLowerCase(); // Convert to lowercase
  s = s.replace(/\W+/g, " "); // Remove punctuation
  s = s.replace(/\s+/g, " "); // Remove extra spaces
  return s.trim(); // Remove leading and trailing spaces
};
