import { Icon } from "@fluentui/react";

type ChevronProps = { collapsed: boolean; hide: boolean };
export const ChevronIcon = ({ collapsed, hide }: ChevronProps) => {
  if (hide) return null;
  return (
    <Icon
      styles={{ root: { cursor: "pointer", fontSize: 8 } }}
      iconName={collapsed ? "ChevronDown" : "ChevronUp"}
    />
  );
};
