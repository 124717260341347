import { DQCColumn, QuickAnalysisConfig, StandardCheck } from "../../types/quickAnalysis";

export const getCheckFromAnalysisConfig = (
  analysisConfig: QuickAnalysisConfig,
  checkName: StandardCheck["name"]
): StandardCheck | undefined => {
  return analysisConfig.checks.find((check) => check.name === checkName);
};

export const getUpdateCheck = (
  analysisConfig: QuickAnalysisConfig,
  setAnalysisConfig: (analysisConfig: QuickAnalysisConfig) => void,
  checkName: StandardCheck["name"]
) => {
  return (updatedCheck: StandardCheck) => {
    const newChecks = analysisConfig.checks.map((check) =>
      check.name !== checkName ? check : updatedCheck
    );
    setAnalysisConfig({ ...analysisConfig, checks: newChecks });
  };
};

export const markAllColumnsSelected = (columns: DQCColumn[], selected: boolean): DQCColumn[] => {
  return columns.map((c) => ({ ...c, selected }));
};
