import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { QuickAnalysisConfig } from "../../types/quickAnalysis";
import { doQuickAnalysis } from "./quickAnalysis";

const analysisConfigAdapter = createEntityAdapter<QuickAnalysisConfig>({
  selectId: (analysisConfig) => analysisConfig.fileInfo.name,
});

export const analysisConfigSlice = createSlice({
  name: "analysisConfig",
  initialState: analysisConfigAdapter.getInitialState(),
  reducers: {
    setAnalysisConfig: (state, analysisConfig: PayloadAction<QuickAnalysisConfig>) => {
      analysisConfigAdapter.upsertOne(state, analysisConfig);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doQuickAnalysis.fulfilled, (state, { payload }) => {
      analysisConfigAdapter.addOne(state, payload.analysisConfig);
    });
  },
});

export const { selectById: selectAnalysisConfigBySheetId, selectAll: selectAllAnalysisConfigs } =
  analysisConfigAdapter.getSelectors((state: RootState) => state.analysisConfig);

export const { setAnalysisConfig } = analysisConfigSlice.actions;
