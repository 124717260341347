import { Stack } from "@fluentui/react";
import { QualityBadge } from "./QualityBadge";
import { getQualityScore } from "../../../helpers/quickAnalysis/qualityChecks/analyze";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { getQualityContainerProps, overallQualityContainerStyles } from "./OverallQuality.styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectActiveFilter, setActiveFilter } from "../../../store/slices/quickAnalysis";
import { OVERALL_QUALITY } from "../../../helpers/quickAnalysis/qualityChecks/constants";
import { InfoIssues } from "../../../components/quick-analysis/InfoIssues";
import { WarningIssues } from "../../../components/quick-analysis/WarningIssues";

export interface OverallQualityProps {
  issues: QuickAnalysisIssue[];
}

export const OverallQuality = ({ issues }: OverallQualityProps) => {
  const activeFilter = useAppSelector(selectActiveFilter);
  const isActiveFilter = activeFilter === OVERALL_QUALITY;

  const dispatch = useAppDispatch();

  return (
    <Stack
      {...getQualityContainerProps(isActiveFilter)}
      onClick={() => dispatch(setActiveFilter(OVERALL_QUALITY))}
    >
      <Stack
        horizontal
        grow
        verticalAlign="center"
        horizontalAlign="space-between"
        styles={overallQualityContainerStyles}
      >
        <QualityBadge qualityScore={getQualityScore(issues, 10)} />
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
          <WarningIssues issues={issues} />
          <InfoIssues issues={issues} />
        </Stack>
      </Stack>
    </Stack>
  );
};
