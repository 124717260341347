export const getQualityContainerStyle = (isActiveFilter: boolean, isHovered: boolean) => {
  const notFilteredColor = isHovered ? "#F0F0F0" : "white";
  return {
    root: {
      boxShadow: isActiveFilter ? "0 0 0 1px #D2D0CE" : "none",
      borderRadius: "4px",
      backgroundColor: notFilteredColor,
    },
  };
};

export const iconStyle = () => {
  return {
    root: {
      fontSize: "1.5em",
      marginRight: 20,
      width: "21px",
      height: "25px",
    },
  };
};
