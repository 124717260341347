import { Stack, TooltipHost, DirectionalHint, Slider, Checkbox, Text, Link } from "@fluentui/react";

import {
  getCheckFromAnalysisConfig,
  getUpdateCheck,
} from "../../../../helpers/quickAnalysis/analyisConfigHelpers";
import {
  OutlierAlgorithm,
  OutlierCheck,
  QualityDimensionSettingsProps,
} from "../../../../types/quickAnalysis";
import { ColumnSelector } from "../ColumnSelector";
import { t } from "i18next";
import { defaultTooltipProps, lightGreyText, sliderProps } from "../Settings.styles";

export const OutlierSettings = ({
  analysisConfig,
  setAnalysisConfig,
  hideColumns,
}: QualityDimensionSettingsProps) => {
  const SLIDER_VALUE_MIN = 1;
  const SLIDER_VALUE_MAX = 5;
  const tickLabels = Array.from({ length: SLIDER_VALUE_MAX }, (_, i) => i + 1);

  const outlierCheck = getCheckFromAnalysisConfig(analysisConfig, "outlier") as OutlierCheck;
  const updateCheck = getUpdateCheck(analysisConfig, setAnalysisConfig, "outlier");
  if (!outlierCheck) return null;

  const outlierSliderOnChange = (value: number) => {
    const newOutlierCheck: OutlierCheck = JSON.parse(JSON.stringify(outlierCheck));
    newOutlierCheck.meta.iqrCutoff = value;
    newOutlierCheck.meta.checkOutliers = value !== 0;
    updateCheck(newOutlierCheck);
  };

  const toggleOutlierSubDimension = (checked: boolean, algorithmName: OutlierAlgorithm) => {
    const newOutlierCheck: OutlierCheck = JSON.parse(JSON.stringify(outlierCheck));
    newOutlierCheck.meta[algorithmName] = checked;
    updateCheck(newOutlierCheck);
  };

  return (
    <ColumnSelector check={outlierCheck} updateCheck={updateCheck} hideColumns={hideColumns}>
      <Stack styles={{ root: { cursor: "default" } }}>
        <TooltipHost
          key={`tooltip_outlier`}
          content={t("outlier_column_tooltip")}
          tooltipProps={defaultTooltipProps}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <Text styles={lightGreyText}>{t("check_outliers")}</Text>
          <Stack horizontal {...sliderProps} style={{ marginTop: "14px", alignItems: "center" }}>
            <Stack style={{ fontSize: "12px" }}>{t("standard-deviation")}</Stack>
            <Slider
              styles={{ root: { width: "80%" } }}
              max={SLIDER_VALUE_MAX}
              min={SLIDER_VALUE_MIN}
              showValue={false}
              onChange={outlierSliderOnChange}
              defaultValue={outlierCheck.meta.iqrCutoff}
              disabled={!outlierCheck.enabled}
            />
          </Stack>
          <Stack horizontal {...sliderProps} className="TickLabelContainer">
            {tickLabels.map((label: number) => (
              <Text variant="small" className="TickLabel" key={`tick_label_text_${label}`}>
                {label}
              </Text>
            ))}
          </Stack>
        </TooltipHost>
        <Stack style={{ marginBottom: "14px" }}>
          <Text variant="small">
            <Link href="https://dqchelp.zendesk.com/hc/en-150/articles/9961456054301-How-outliers-are-calculated">
              {t("about-outliers-calculation")}
            </Link>
          </Text>
        </Stack>
        <Stack styles={{ root: { marginLeft: 8 } }} tokens={{ childrenGap: 4 }}>
          <Checkbox
            label={t("consider_zeros")}
            checked={outlierCheck.meta.considerZeros}
            onChange={(_, checked) => toggleOutlierSubDimension(!!checked, "considerZeros")}
            disabled={!outlierCheck.enabled}
          />
          <Checkbox
            label={t("check_different_sign")}
            checked={outlierCheck.meta.checkSign}
            onChange={(_, checked) => toggleOutlierSubDimension(!!checked, "checkSign")}
            disabled={!outlierCheck.enabled}
          />
        </Stack>
      </Stack>
    </ColumnSelector>
  );
};
