import { QuickAnalysisIssue } from "../../types/quickAnalysis";

export type ArrowKey = "ArrowDown" | "ArrowUp" | "ArrowLeft" | "ArrowRight";
export const ARROW_DOWN: ArrowKey = "ArrowDown";
export const ARROW_UP: ArrowKey = "ArrowUp";
export const ARROW_LEFT: ArrowKey = "ArrowLeft";
export const ARROW_RIGHT: ArrowKey = "ArrowRight";
export const NEXT = 1;
export const PREV = -1;
export const NO_DIRECTION = 0;

export const handleSlide = ({
  sliderRef,
  selectedStateRef,
  arrowDirection,
  selectedIssue,
}: {
  sliderRef: React.MutableRefObject<any>;
  selectedStateRef: React.MutableRefObject<any>;
  arrowDirection: ArrowKey;
  selectedIssue: QuickAnalysisIssue | undefined;
}) => {
  if (sliderRef.current && selectedIssue) {
    if (selectedIssue.type !== "duplicate")
      handleInfineteScroll({ sliderRef, selectedStateRef, arrowDirection });
    handleSingleItemScroll({ sliderRef, selectedStateRef, selectedIssue });
  }
};

export const handleSingleItemScroll = ({
  sliderRef,
  selectedStateRef,
  selectedIssue,
}: {
  sliderRef: React.MutableRefObject<any>;
  selectedStateRef: React.MutableRefObject<any>;
  selectedIssue: QuickAnalysisIssue | undefined;
}) => {
  //Handle single item scroll
  selectedStateRef.current = `Row: ${selectedIssue?.row}`;
  const visibleElements = sliderRef.current.visibleElements;
  const lastVisibleElement = visibleElements[visibleElements.length - 1];
  const firstVisibleElement = visibleElements[0];
  const isLastOnScreen = selectedStateRef.current === lastVisibleElement;
  const isFirstOnScreen = selectedStateRef.current === firstVisibleElement;
  if (visibleElements.includes(selectedStateRef.current)) {
    slideToVisibleNextPrevElement(isLastOnScreen, isFirstOnScreen, sliderRef);
  } else {
    slideToInVisibleElement(sliderRef, selectedStateRef);
  }
};

const slideToVisibleNextPrevElement = (
  isLastOnScreen: boolean,
  isFirstOnScreen: boolean,
  sliderRef: React.MutableRefObject<any>
) => {
  if (isLastOnScreen) {
    sliderRef.current.scrollToItem(sliderRef.current.getNextElement(), "smooth", "end");
  } else if (isFirstOnScreen) {
    sliderRef.current.scrollToItem(sliderRef.current.getPrevElement(), "smooth", "start");
  }
};

const slideToInVisibleElement = (
  sliderRef: React.MutableRefObject<any>,
  selectedStateRef: React.MutableRefObject<any>
) => {
  const listItems = [...sliderRef.current.items.toItemsWithoutSeparators()];
  if (listItems.includes(selectedStateRef.current) || listItems.length === 0) {
    sliderRef.current.scrollToItem(
      document.querySelector(`[data-key='${selectedStateRef.current}']`),
      "smooth",
      "start"
    );
  }
};

const handleInfineteScroll = ({
  sliderRef,
  selectedStateRef,
  arrowDirection,
}: {
  sliderRef: React.MutableRefObject<any>;
  selectedStateRef: React.MutableRefObject<any>;
  arrowDirection: ArrowKey;
}) => {
  //Handle infinite scroll
  const previousSelectedState = selectedStateRef.current;
  const listItems = [...sliderRef.current.items.toItemsWithoutSeparators()];
  let isLastItemInListVisible = false;
  let isFirstItemInListVisible = false;
  if (previousSelectedState) {
    isLastItemInListVisible = previousSelectedState === listItems[listItems.length - 1];
    isFirstItemInListVisible = previousSelectedState === listItems[0];
  }

  if (isLastItemInListVisible && arrowDirection === ARROW_RIGHT) {
    sliderRef.current.scrollToItem(
      document.querySelector(`[data-key='${listItems[0]}']`),
      "smooth",
      "start"
    );
  } else if (isFirstItemInListVisible && arrowDirection === ARROW_LEFT) {
    sliderRef.current.scrollToItem(
      document.querySelector(`[data-key='${listItems[listItems.length - 1]}']`),
      "smooth",
      "end"
    );
  }
};
/**
 * Given two addresses this function sorts first by column then by row
 * @param a
 * @param b
 * @returns -1 or +1 depending on which issue is further top left
 */
export const sortByAddress = (a: string, b: string) => {
  const regex = /[a-zA-Z]+|\d+/g;
  const aParts = a.match(regex);
  const bParts = b.match(regex);
  if (!aParts) return -1;
  if (!bParts) return 1;
  const aLetters = aParts[0];
  const bLetters = bParts[0];
  const aNumbers = parseInt(aParts[1]);
  const bNumbers = parseInt(bParts[1]);
  if (aLetters < bLetters) {
    return -1;
  } else if (aLetters > bLetters) {
    return 1;
  } else {
    return aNumbers - bNumbers;
  }
};

/**
 * Sorts issues by their address field first by column then by row
 * @param a
 * @param b
 * @returns -1 or +1 depending on which issue is further top left
 */
export const sortIssueByAddress = (
  a: QuickAnalysisIssue,
  b: QuickAnalysisIssue,
  isHorizontal: boolean
): number => {
  const isNotSortDuplicates = a.type !== "duplicate" && b.type !== "duplicate";
  const rowDifference = a.row - b.row;
  const columnDifference = isNotSortDuplicates ? a.column - b.column : 0;
  if (isHorizontal) return rowDifference === 0 ? columnDifference : rowDifference;
  else return columnDifference === 0 ? rowDifference : columnDifference;
};
