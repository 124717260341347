export const QualityScore = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
} as const;

export const QualityDimension = {
  UNDEFINED: "UNDEFINED",
  MISSING: "MISSING",
  INCONSISTENT: "INCONSISTENT",
  OUTLIERS: "OUTLIERS",
  DUPLICATES: "DUPLICATES",
  REMARKS: "REMARKS",
  NEW_RECORD: "NEW_RECORD",
  OUTDATED: "OUTDATED",
} as const;

export type QualityScoreKeys = keyof typeof QualityScore;
export type QualityDimensionKeys = keyof typeof QualityDimension;

export interface DatasetQualityDetail {
  id: string;
  count: number;
}

export interface QualityDimensionAggregate {
  quality_score: typeof QualityScore[QualityScoreKeys] | number;
  count: number;
  dataset_details: Array<DatasetQualityDetail>;
}

export type IssueCountModel = {
  count: number;
  quality_dimension: typeof QualityDimension[QualityDimensionKeys];
  quality_score: typeof QualityScore[QualityScoreKeys];
};

export type DatasetQualitySummary = {
  dataset_id: string;
  issue_counts: IssueCountModel[];
  etag: string;
  score: number;
  quality_score: typeof QualityScore[QualityScoreKeys];
};

export type QualitySummary = {
  id: string;
  mission_id: string;
  dataset_quality_summaries: DatasetQualitySummary[];
  combined_quality: number;
  combined_issue_counts: IssueCountModel[];
  created_at: string;
  updated_at: string;
  status: "loading_data" | "analyzing_data" | "writing_results" | "done" | "failed";
};

export type ColumnInfo = {
  name: string;
  id: string;
  index: number;
};

type DuplicateConfig = {
  selected_columns: ColumnInfo[];
  threshold: number;
};

type MissingConfig = {
  selected_columns: ColumnInfo[];
};

type OutdatedConfig = {
  days: number;
};

type OutlierConfig = {
  iqr: MissingConfig;
  sign: MissingConfig;
  string_length: MissingConfig;
};

export type Config = {
  duplicate: DuplicateConfig;
  missing: MissingConfig;
  outdated: OutdatedConfig;
  outlier: OutlierConfig;
  weighting: number;
};

export type QualityConfig = {
  mission_id: string;
  dataset_id: string;
  config: Config;
  all_column_infos: ColumnInfo[];
};

export type QualityConfigQuery = {
  mission_id: string;
  dataset_id: string;
};
