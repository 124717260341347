import { DirectionalHint, Icon, Stack, TooltipHost, Text } from "@fluentui/react";
import { t } from "i18next";
import { getWarningIconContainerProps, getWarningIssuesStackProps } from "./WarningsIssues.styles";
import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { defaultTooltipProps } from "./settings/Settings.styles";

type WarningIssuesProps = {
  issues: QuickAnalysisIssue[];
};

export const WarningIssues = ({ issues }: WarningIssuesProps) => {
  const warningIssues = issues.filter(
    (issue) => issue.severity === "warning" && !issue.isIgnored && !issue.isSolved
  );
  const disabled = !warningIssues.length;
  const warningIcon = <Icon iconName="Warning" />;

  return (
    <Stack
      //  onClick={filterWarningIssues}
      {...getWarningIssuesStackProps()}
    >
      {warningIssues.length ? (
        <TooltipHost
          content={t("warning_tooltip")}
          tooltipProps={defaultTooltipProps}
          directionalHint={DirectionalHint.topLeftEdge}
        >
          <Stack {...getWarningIconContainerProps(disabled)}>
            <Text>{warningIssues.length}</Text>
            {warningIcon}
          </Stack>
        </TooltipHost>
      ) : (
        <Stack {...getWarningIconContainerProps(disabled)}>
          <Text>-</Text>
          {warningIcon}
        </Stack>
      )}
    </Stack>
  );
};
