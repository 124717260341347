import React, { useEffect, useState } from "react";
import {
  ConfigureModalProps,
  ConfigurationMapValue,
  TopConfigMapValue,
} from "../../../types/missions";
import { modalHeader, modalSize } from "../../../helpers/commonStyles";
import { ConfigureColumnList } from "./ConfigureColumnList";
import { ConfigureContent } from "./ConfigureContent";
import { getSelectedColumns } from "../../../helpers/dataset-helper";
import { columnArray } from "../../../helpers/dataset-helper";
import {
  Modal,
  Label,
  Icon,
  Stack,
  IStackStyles,
  IIconStyles,
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useGetQualityConfigQuery, useUpdateQualityMutation } from "../../../api/mission";

const settingContainer: IStackStyles = {
  root: {
    padding: "3%",
  },
};

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};

const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 20,
    cursor: "pointer",
  },
};

const buttonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "4%",
};

const buttonSubContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

export const ConfigureModal: React.FunctionComponent<ConfigureModalProps> = ({
  openCloseModal,
  mission,
  dataset,
  secondaryButtonOnClick,
}) => {
  const { t } = useTranslation();
  const [updateQuality] = useUpdateQualityMutation();
  const qualityConfig = useGetQualityConfigQuery({
    mission_id: mission?.id!,
    dataset_id: dataset?.id!,
  });
  let defaultConfiguration: Record<string, ConfigurationMapValue> = {};

  columnArray.forEach((key) => {
    defaultConfiguration = { ...defaultConfiguration, [key]: { toggle: false, column: [] } };
  });

  const defaultTopConfig: Record<string, TopConfigMapValue> = {
    weighting: {
      value: 1,
      label: t("edit_weighting_of_dataset"),
      placeHolder: t("edit_weighting_of_dataset_placeholder"),
      type: "number",
      step: "0.01",
    },
    outdatedDays: {
      value: 0,
      label: t("edit_days_until_outdated"),
      placeHolder: t("edit_days_until_outdated_placeholder"),
      type: "number",
      step: "",
    },
    threshold: {
      value: 0.66,
      label: t("edit_duplicate_threshold"),
      placeHolder: t("edit_duplicate_threshold_placeholder"),
      type: "number",
      step: "0.01",
    },
  };
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [topConfig, setTopConfig] = useState<Record<string, TopConfigMapValue>>(defaultTopConfig);
  const [configuration, setConfiguration] =
    useState<Record<string, ConfigurationMapValue>>(defaultConfiguration);

  useEffect(() => {
    if (qualityConfig.isError) {
      setErrorMessage(JSON.stringify(qualityConfig.error));
      return;
    }
    if (qualityConfig.isSuccess) {
      const config = defaultTopConfig;
      config["threshold"].value = qualityConfig.currentData?.config.duplicate?.threshold!;
      config["outdatedDays"].value = qualityConfig.currentData?.config.outdated?.days! || 0;
      config["weighting"].value = qualityConfig.currentData?.config.weighting!;
      setTopConfig(config);

      const {
        selectedDuplicateColumns,
        selectedMissingColumns,
        selectedIQRColumns,
        selectedSignColumns,
        selectedStringLengthColumns,
      } = getSelectedColumns(qualityConfig.currentData!);
      setConfiguration({
        ...configuration,
        [columnArray[0]]: {
          toggle:
            selectedDuplicateColumns?.length === qualityConfig.currentData!.all_column_infos.length,
          column: selectedDuplicateColumns,
        },
        [columnArray[1]]: {
          toggle:
            selectedMissingColumns?.length === qualityConfig.currentData!.all_column_infos.length,
          column: selectedMissingColumns,
        },
        [columnArray[2]]: {
          toggle: selectedIQRColumns?.length === qualityConfig.currentData!.all_column_infos.length,
          column: selectedIQRColumns,
        },
        [columnArray[3]]: {
          toggle:
            selectedSignColumns?.length === qualityConfig.currentData!.all_column_infos.length,
          column: selectedSignColumns,
        },
        [columnArray[4]]: {
          toggle:
            selectedStringLengthColumns?.length ===
            qualityConfig.currentData!.all_column_infos.length,
          column: selectedStringLengthColumns,
        },
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityConfig]);

  const saveConfigurations = async () => {
    setSaveLoading(true);
    const resp = updateQuality({
      mission_id: qualityConfig.data?.mission_id,
      dataset_id: qualityConfig.data?.dataset_id,
      config: {
        missing: { selected_columns: configuration[columnArray[1]].column },
        duplicate: {
          selected_columns: configuration[columnArray[0]].column,
          threshold: topConfig.threshold.value || 0.66,
        },
        outlier: {
          iqr: { selected_columns: configuration[columnArray[2]].column },
          sign: { selected_columns: configuration[columnArray[3]].column },
          string_length: { selected_columns: configuration[columnArray[4]].column },
        },
        outdated: { days: topConfig.outdatedDays.value || 0 },
        weighting:
          topConfig.weighting.value === null || topConfig.weighting.value < 0
            ? 1
            : topConfig.weighting.value,
      },
    }).unwrap();
    resp
      .then(() => {
        setSaveLoading(false);
        handleCloseModal();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleCloseModal = () => {
    secondaryButtonOnClick();
  };

  const onConfigChange = (value: number, key: string, fieldData: TopConfigMapValue) => {
    const updatedFieldData = { ...fieldData, value: value };
    setTopConfig({ ...topConfig, [key]: updatedFieldData });
  };

  return (
    <Modal
      isOpen={openCloseModal}
      onDismiss={() => handleCloseModal()}
      containerClassName={modalSize.container}
    >
      <Stack horizontal styles={headerContainer}>
        <Label styles={modalHeader}>{t("edit_column_config_title")}</Label>
        <Icon iconName="ChromeClose" onClick={() => handleCloseModal()} styles={iconStyle} />
      </Stack>
      <div style={{ border: "1px solid #DDDDDD" }} />
      <Stack styles={settingContainer}>
        {qualityConfig.isLoading ? (
          <>
            <Spinner size={SpinnerSize.large} style={{ marginTop: "20%", marginBottom: "20%" }} />
            <Stack.Item>
              <h3 style={{ textAlign: "center" }}>{t("edit_column_config_loading")}</h3>
            </Stack.Item>
          </>
        ) : (
          <>
            <ConfigureContent
              topConfig={topConfig}
              onFieldChange={(
                fieldValue: number,
                fieldKey: string,
                fieldData: TopConfigMapValue
              ) => {
                onConfigChange(fieldValue, fieldKey, fieldData);
              }}
              errorMessage={errorMessage}
            />
            {/* Table starts */}
            <ConfigureColumnList
              qualityConfig={qualityConfig.data}
              configuration={configuration}
              setConfiguration={setConfiguration}
            />
            <div style={buttonContainer}>
              <div style={buttonSubContainer}>
                <PrimaryButton
                  text={saveLoading ? t("saving") : t("save")}
                  onClick={saveConfigurations}
                  styles={{
                    root: { marginRight: "5%", whiteSpace: "noWrap" },
                  }}
                  disabled={errorMessage !== "" ? true : false}
                />
                <DefaultButton
                  text={t("cancel")}
                  onClick={() => handleCloseModal()}
                  styles={{ root: { marginRight: "2%" } }}
                />
              </div>
            </div>
          </>
        )}
      </Stack>
    </Modal>
  );
};
