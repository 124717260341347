import { IStackStyles, Label, Stack } from "@fluentui/react";
import React from "react";
import { subTopicLabel } from "../../../helpers/commonStyles";
import { TopConfigMapValue } from "../../../types/missions";

const labelContainer: IStackStyles = {
  root: {
    width: "70%",
  },
};
export interface ConfigureInputBoxProps {
  fieldData: TopConfigMapValue;
  onChange(value: number): void;
}

export const ConfigureInputBox: React.FunctionComponent<ConfigureInputBoxProps> = ({
  fieldData,
  onChange,
}) => {
  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
        <Stack.Item styles={labelContainer}>
          <Label styles={subTopicLabel}>{fieldData.label}</Label>
        </Stack.Item>
        <Stack.Item grow={1}>
          <input
            type={fieldData.type}
            step={fieldData.step}
            value={fieldData.value}
            placeholder={fieldData.placeHolder}
            style={{ height: "28px", width: "166px" }}
            onChange={(e: any) => onChange(e.target.value)}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};
