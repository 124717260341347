import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuickAnalysisIssue, UploadDataContainer } from "../../types/quickAnalysis";
import { RootState } from "../store";
import { CellClassParams, CellStyleFunc, ColDef } from "ag-grid-community";
import { DQCTableData, DQCTableValue } from "../../types/fileUploader";
import { dqcPalette } from "../../utils/colors";
import { selectActiveFilter } from "./quickAnalysis";
const uploadEntityAdapter = createEntityAdapter<UploadDataContainer>({
  selectId: (upload) => upload.fileName,
});

export const uploadSlice = createSlice({
  name: "upload",
  initialState: uploadEntityAdapter.getInitialState(),
  reducers: {
    setUploadFile: (state, payload: PayloadAction<UploadDataContainer>) => {
      uploadEntityAdapter.setOne(state, payload);
    },
    clearAllFiles: (state) => {
      uploadEntityAdapter.removeAll(state);
    },
  },
});
export const { selectById: selectUploadByFileName } = uploadEntityAdapter.getSelectors();
export const { setUploadFile, clearAllFiles } = uploadSlice.actions;

const selectUploadeState = (state: RootState) => state.upload;
export const selectLatestUpload = createSelector(
  selectUploadeState,
  (uploadSlice): UploadDataContainer | undefined => {
    if (uploadSlice.ids.length === 0) return undefined;
    const latestId = uploadSlice.ids[uploadSlice.ids.length - 1];
    return uploadSlice.entities[latestId];
  }
);

const selectQuickAnalysisSlice = (state: RootState) => state.quickAnalysis;

export const selectColumnDefsFromLatestUpload = createSelector(
  selectLatestUpload,
  selectActiveFilter,
  (uploadContainer, activeFilter): ColDef<DQCTableData, DQCTableValue>[] | undefined => {
    if (!uploadContainer) return undefined;
    const cellStyle = getCellStyleFunction(activeFilter);
    return uploadContainer.headerRow.map((columnName, columnIndex) => ({
      headerName: columnName,
      field: `${columnIndex}.value`,
      filter: true,
      cellStyle,
      width: 120,
      resizable: true,
    }));
  }
);
const getCellStyleFunction = (activeFilter?: string) => {
  const cellStyle: CellStyleFunc<DQCTableData, DQCTableValue> = ({
    colDef,
    data,
  }: CellClassParams) => {
    const columnIndexString = colDef.field?.split(".")[0] || "";
    const columnIndex = parseInt(columnIndexString);
    if (data[columnIndex]?.issues?.length) {
      const issue = data[columnIndex]?.issues[0];
      if (activeFilter !== issue.type) {
        const backgroundColor =
          issue.severity === "warning" ? dqcPalette.warningIssueFill : dqcPalette.issueFill;
        return { backgroundColor };
      } else {
        const backgroundColor =
          issue.severity === "warning" ? dqcPalette.warningIssueFiltered : dqcPalette.issueFiltered;
        return { backgroundColor };
      }
    }
    return null;
  };
  return cellStyle;
};

export const selectRowsFromLatestUpload = createSelector(
  selectLatestUpload,
  selectQuickAnalysisSlice,
  (uploadContainer, { issues }): Record<number, DQCTableValue>[] | undefined => {
    const issuesByRowColumnIndex: Record<string, QuickAnalysisIssue[]> = {};
    issues.forEach((issue) => {
      const issueColumns = issue.type === "duplicate" ? issue.columns : [issue.column];
      for (let column of issueColumns) {
        const issueId = getIssueRowColumnIndex(issue.row, column);
        if (issuesByRowColumnIndex[issueId]) issuesByRowColumnIndex[issueId].push(issue);
        else issuesByRowColumnIndex[issueId] = [issue];
      }
    });
    if (!uploadContainer) return undefined;
    return uploadContainer.data.map((row, rowIndex) =>
      row.reduce((rowMap, cell, columnIndex) => {
        const rowColumnIndex = getIssueRowColumnIndex(rowIndex, columnIndex);
        const issues = issuesByRowColumnIndex[rowColumnIndex];
        rowMap[columnIndex] = { value: cell.value, issues };
        return rowMap;
      }, {} as Record<number, DQCTableValue>)
    );
  }
);

const getIssueRowColumnIndex = (row: number, column = 0): string => {
  return `${row}::${column}`;
};
