import { IIconStyles, IStackProps, ITextStyles } from "@fluentui/react";
export const getQualityLabelContainerStyles = (color: string): IStackProps => ({
  horizontal: true,
  verticalAlign: "center",
  styles: {
    root: {
      color: "white",
      background: `linear-gradient(${color},${color})`,
      WebkitMaskImage: "url('/rectangular_arrow.svg')",
      MaskImage: "url('/rectangular_arrow.svg')",
      width: 127,
      height: 32,
    },
  },
});

export const qualityTextStyle = (isActiveWarningFilter: boolean): ITextStyles => ({
  root: {
    color: isActiveWarningFilter ? "black" : "white",
    fontWeight: 600,
    fontSize: 14,
    marginLeft: 10,
  },
});

export const qualityIconStyle: IIconStyles = { root: { margin: 10, height: 14 } };
