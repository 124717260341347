import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CellClickedEvent, ColDef, IRowNode } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectColumnDefsFromLatestUpload,
  selectRowsFromLatestUpload,
} from "../../store/slices/upload";
import { DQCTableData, DQCTableValue } from "../../types/fileUploader";
import { selectActiveFilter } from "../../store/slices/quickAnalysis";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";
import { OVERALL_QUALITY } from "../../helpers/quickAnalysis/qualityChecks/constants";
import { handleSelectIssueInSheet } from "../../store/slices/issueNavigator";

export const DataGrid = ({ height, width }: { height: number; width: number }) => {
  const gridRef = useRef<AgGridReactType>();
  const dispatch = useAppDispatch();
  const columnDefs: ColDef[] | undefined = useAppSelector(selectColumnDefsFromLatestUpload);
  const rowData = useAppSelector(selectRowsFromLatestUpload);
  const activeFilter = useAppSelector(selectActiveFilter);
  useEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [activeFilter]);

  const isExternalFilterPresent = useCallback(() => {
    return activeFilter !== undefined;
  }, [activeFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }: IRowNode<DQCTableData>) => {
      if (activeFilter === undefined) return true;
      if (!data) return false;
      if (activeFilter === OVERALL_QUALITY)
        return Object.values(data).some(({ issues }) => issues?.length);
      return Object.values(data).some(
        ({ issues }) => issues?.filter((issue) => issue.type === activeFilter)?.length
      );
    },
    [activeFilter]
  );
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      filter: true,
    };
  }, []);
  const onCellClicked = ({ colDef, data }: CellClickedEvent<DQCTableData, DQCTableValue>) => {
    const columnIndexString = colDef.field?.split(".")[0] || "";
    const columnIndex = parseInt(columnIndexString);
    if (!data) return;
    const issue = data[columnIndex]?.issues?.[0];
    if (issue) {
      dispatch(handleSelectIssueInSheet({ issue }));
    }
  };

  if (!columnDefs || !rowData) return null;
  return (
    // TODO figure out correct size for grid view...
    <div className="ag-theme-alpine" style={{ height, width }}>
      <AgGridReact<DQCTableData>
        onCellClicked={onCellClicked}
        rowData={rowData}
        columnDefs={columnDefs}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        defaultColDef={defaultColDef}
        // TODO type this gridRef correctly
        // @ts-ignore
        ref={gridRef}
      />
    </div>
  );
};
