import Papa from "papaparse";
import { UploadDQCDataType, UploadDataContainer } from "../../types/quickAnalysis";
import { WorkBook, read, utils } from "xlsx";

export const getDataTypeFromRawValue = (value: any): UploadDQCDataType["type"] => {
  if (typeof value === "string") return "String";
  if (typeof value === "number") {
    return Number.isInteger(value) ? "Integer" : "Double";
  }
  if (typeof value === "boolean") return "Boolean";
  if (value === "" || value === null || value === undefined) return "Empty";
  return "Unknown";
};

export const getUploadDataFrameFromCSVFile = (
  event: ProgressEvent<FileReader>,
  fileName: string
): UploadDataContainer => {
  const content = event?.target?.result as string;
  const parsed = Papa.parse(content, { dynamicTyping: true, header: true });
  const headerRow = parsed.meta.fields ?? [];
  const data: UploadDQCDataType[][] = parsed.data.map((row: any, rowIndex: number) => {
    return headerRow.map((column: string, colIndex: number) => {
      const value = row[column];
      return {
        value: value,
        type: getDataTypeFromRawValue(value),
        row: rowIndex,
        column: colIndex,
      };
    });
  });
  return {
    data: data,
    headerRow: headerRow,
    fileName,
  };
};

export const getUploadDataFrameFromExcelFile = (
  event: ProgressEvent<FileReader>,
  fileName: string
): UploadDataContainer => {
  const bstr = event?.target?.result;
  const wb: WorkBook = read(bstr, { type: "binary" });

  // Get first sheet
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];

  // Convert array of arrays
  const parsedData: any[] = utils.sheet_to_json(ws, {
    header: 1,
    rawNumbers: false,
    blankrows: false,
  });
  const maxNumberOfCellsInARow = Math.max(...parsedData.map((row) => row.length));
  const headerRow: string[] = [];
  for (let i = 0; i < maxNumberOfCellsInARow; i++) {
    const headerRowItem = parsedData[0]?.[i] ?? `Column ${i + 1}`;
    headerRow.push(headerRowItem);
  }
  const data: UploadDQCDataType[][] = [];
  for (let row = 1; row < parsedData.length; row++) {
    const dataRow: UploadDQCDataType[] = [];
    for (let column = 0; column < maxNumberOfCellsInARow; column++) {
      const formattedValue: string = parsedData[row]?.[column];
      const dataCell = getValueAndTypeForFormattedExcelValue(formattedValue, row - 1, column);

      dataRow.push(dataCell);
    }
    data.push(dataRow);
  }
  return {
    data: data,
    headerRow: headerRow,
    fileName,
  };
};

export const getValueAndTypeForFormattedExcelValue = (
  formattedValue: string | undefined,
  row: number,
  column: number
): UploadDQCDataType => {
  const rowColumnExtra = { row, column };
  if (formattedValue === undefined)
    return {
      value: "",
      type: "Empty",
      ...rowColumnExtra,
    };
  const validNumberPattern = /^-?\d*[,.]?\d*$/;
  const isValidNumber = validNumberPattern.test(formattedValue);
  if (!isValidNumber)
    return {
      value: formattedValue,
      type: getDataTypeFromRawValue(formattedValue),
      ...rowColumnExtra,
    };
  const valueAsFloat = parseFloat(formattedValue);
  const valueAsInt = parseInt(formattedValue);
  const isInt = valueAsFloat === valueAsInt;
  if (isInt)
    return {
      value: valueAsInt,
      type: "Integer",
      ...rowColumnExtra,
    };
  return {
    value: valueAsFloat,
    type: "Double",
    ...rowColumnExtra,
  };
};
