import { addIssueId } from "./qualityUtils";
import { findMissingIssues } from "./missing";
import {
  QuickAnalysisIssue,
  QualityScore,
  QuickAnalysisConfig,
  UploadDataContainer,
} from "../../../types/quickAnalysis";
import { findPiiIssues } from "./pii";
import { findDuplicateIssues } from "./duplicate";
import { findOutlierIssues } from "./outlier";
import { findInconsistentIssues } from "./inconsistent";

export const findIssues = (
  dataContainer: UploadDataContainer,
  analysisConfig: QuickAnalysisConfig
): QuickAnalysisIssue[] => {
  return analysisConfig.checks
    .map((check) => {
      if (check.name === "missing") return findMissingIssues(dataContainer, check);
      if (check.name === "sensitive") return findPiiIssues(dataContainer, check);
      if (check.name === "duplicate") return findDuplicateIssues(dataContainer, check);
      if (check.name === "outlier") return findOutlierIssues(dataContainer, check);
      if (check.name === "inconsistent") return findInconsistentIssues(dataContainer, check);
      return [];
    })
    .map(addIssueId)
    .flat();
};

const WARNING_ISSUE_WEIGHTING = 0.8;
export const getQualityScore = (issues: QuickAnalysisIssue[], totalRows: number): QualityScore => {
  const outstandingIssues = issues.filter((issue) => !issue.isIgnored && !issue.isSolved);
  const warningIssues = outstandingIssues.filter((issue) => issue.severity === "warning");
  const infoIssues = outstandingIssues.filter((issue) => issue.severity === "info");
  const infoScore = Math.min(infoIssues.length / totalRows, 1);
  const warningScore = Math.min(warningIssues.length / totalRows, 1);
  const score = WARNING_ISSUE_WEIGHTING * warningScore + (1 - WARNING_ISSUE_WEIGHTING) * infoScore;
  if (score > 0.59) return "E";
  if (score > 0.39) return "D";
  if (score > 0.25) return "C";
  if (score > 0.1) return "B";
  return "A";
};
