import { MissingCheck, MissingIssue, UploadDataContainer } from "../../../types/quickAnalysis";
import { isColumnSelected } from "./qualityUtils";

const WARNING_MISSING_RATIO = 0.1;

export const findMissingIssues = (
  dataContainer: UploadDataContainer,
  missingCheck?: MissingCheck
): MissingIssue[] => {
  if (!missingCheck?.enabled) return [];
  const issues2D: MissingIssue[][] = [];
  const columnsLength = dataContainer.data[0].length;
  for (let column = 0; column < columnsLength; column++) {
    if (!isColumnSelected(column, missingCheck)) continue;
    const innerIssues: MissingIssue[] = [];
    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      if (missingCheck.meta.valuesConsideredMissing.includes(cell.value.toString())) {
        innerIssues.push({
          row: cell.row,
          column: cell.column,
          type: "missing",
          comment: "missing_comment",
          severity: "info",
        });
      }
    }
    issues2D.push(innerIssues);
  }

  return setMissingIssueSeverity(issues2D, dataContainer.data.length);
};

const setMissingIssueSeverity = (issues: MissingIssue[][], numRows: number): MissingIssue[] => {
  const issuesWithSeverity: MissingIssue[][] = issues.map((columnIssues) => {
    if (columnIssues.length / numRows < WARNING_MISSING_RATIO) {
      return columnIssues.map((i) => {
        return { ...i, severity: "warning", comment: "exceptional_missing_comment" };
      });
    } else return columnIssues;
  });
  return issuesWithSeverity.flat(1);
};
