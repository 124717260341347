import {
  InconsistentCheck,
  InconsistentIssue,
  UploadDQCDataType,
  UploadDataContainer,
} from "../../../types/quickAnalysis";
import { isColumnSelected } from "./qualityUtils";
import { getMostFrequent } from "../../../utils/strings";
import { EMPTY_CELL_VALUES } from "./constants";

export const findInconsistentIssues = (
  dataContainer: UploadDataContainer,
  inconsistentCheck: InconsistentCheck
): InconsistentIssue[] => {
  if (!inconsistentCheck.enabled) return [];
  const issues: InconsistentIssue[] = [];
  for (let column = 0; column < dataContainer.data[0].length; column++) {
    if (!isColumnSelected(column, inconsistentCheck)) continue;
    const nonEmptyItems = dataContainer.data
      .filter((row) => !EMPTY_CELL_VALUES.includes(row[column].value.toString()))
      .map((row) => row[column]);

    const mostFrequentType = getMostFrequent(
      nonEmptyItems.map((cell) => cellTypeToGroup[cell.type])
    );
    for (let row = 0; row < dataContainer.data.length; row++) {
      const cell = dataContainer.data[row][column];
      const isCellEmpty = EMPTY_CELL_VALUES.includes(cell.value.toString());
      if (isCellEmpty) continue;
      const isCellTypeIssue = mostFrequentType && mostFrequentType !== cellTypeToGroup[cell.type];
      if (isCellTypeIssue) {
        issues.push({
          column: cell.column,
          row: cell.row,
          type: "inconsistent",
          severity: "info",
          comment: getCellTypeComment(cell.type, mostFrequentType),
        });
      }
    }
  }
  return issues;
};

const cellTypeToGroup: Record<UploadDQCDataType["type"], string> = {
  Boolean: "boolean",
  Integer: "number",
  Double: "number",
  String: "text",
  Empty: "text",
  Error: "text",
  RichValue: "text",
  Unknown: "text",
};
export const getCellTypeComment = (
  cellType: UploadDQCDataType["type"],
  mostFrequentType: string
): string => {
  const typeGroup = cellTypeToGroup[cellType];
  return `${typeGroup}_in_${mostFrequentType}_comment`;
};
