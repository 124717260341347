import { PiiIssue, UploadDQCDataType } from "../../../types/quickAnalysis";

const t = new Set<string>([
  "maria",
  "ursula",
  "monika",
  "petra",
  "elisabeth",
  "sabine",
  "renate",
  "helga",
  "karin",
  "brigitte",
  "ingrid",
  "erika",
  "andrea",
  "gisela",
  "claudia",
  "susanne",
  "gabriele",
  "christa",
  "christine",
  "hildegard",
  "anna",
  "birgit",
  "barbara",
  "gertrud",
  "heike",
  "marianne",
  "elke",
  "martina",
  "angelika",
  "irmgard",
  "inge",
  "elfriede",
  "doris",
  "marion",
  "ruth",
  "ulrike",
  "hannelore",
  "jutta",
  "gerda",
  "kerstin",
  "ilse",
  "anneliese",
  "margarete",
  "ingeborg",
  "anja",
  "edith",
  "sandra",
  "waltraud",
  "beate",
  "rita",
  "katharina",
  "christel",
  "nicole",
  "regina",
  "eva",
  "rosemarie",
  "erna",
  "manuela",
  "sonja",
  "johanna",
  "irene",
  "silke",
  "gudrun",
  "christiane",
  "cornelia",
  "tanja",
  "anita",
  "bettina",
  "silvia",
  "daniela",
  "sigrid",
  "simone",
  "stefanie",
  "annette",
  "bärbel",
  "michaela",
  "angela",
  "dagmar",
  "heidi",
  "annemarie",
  "helene",
  "anke",
  "margot",
  "sylvia",
  "christina",
  "katrin",
  "melanie",
  "hedwig",
  "roswitha",
  "martha",
  "alexandra",
  "else",
  "iris",
  "katja",
  "charlotte",
  "lieselotte",
  "hilde",
  "astrid",
  "anni",
  "margit",
  "frieda",
  "carmen",
  "anne",
  "ilona",
  "luise",
  "margret",
  "dorothea",
  "rosa",
  "herta",
  "olga",
  "lydia",
  "julia",
  "marlies",
  "yvonne",
  "antje",
  "käthe",
  "kathrin",
  "agnes",
  "gerlinde",
  "irma",
  "vera",
  "edeltraud",
  "ines",
  "stephanie",
  "carola",
  "franziska",
  "heidrun",
  "marina",
  "britta",
  "nadine",
  "diana",
  "ellen",
  "elvira",
  "sieglinde",
  "gabi",
  "emma",
  "veronika",
  "marita",
  "theresia",
  "bianca",
  "klara",
  "kirsten",
  "magdalena",
  "adelheid",
  "annegret",
  "brunhilde",
  "nina",
  "ina",
  "hanna",
  "uta",
  "heidemarie",
  "therese",
  "gertraud",
  "ramona",
  "lisa",
  "marga",
  "paula",
  "marlene",
  "irina",
  "berta",
  "tatjana",
  "verena",
  "marie",
  "anette",
  "evelyn",
  "jana",
  "elena",
  "elli",
  "mathilde",
  "eva-maria",
  "corinna",
  "wilma",
  "lore",
  "eleonore",
  "judith",
  "sabrina",
  "gaby",
  "karola",
  "liselotte",
  "elsa",
  "elsbeth",
  "isolde",
  "ida",
  "heide",
  "ella",
  "tina",
  "thea",
  "regine",
  "dora",
  "sibylle",
  "liane",
  "kornelia",
  "mechthild",
  "waltraut",
  "lotte",
  "sybille",
  "dorothee",
  "josefine",
  "annett",
  "steffi",
  "margarethe",
  "gabriela",
  "maren",
  "patricia",
  "marie-luise",
  "juliane",
  "pia",
  "valentina",
  "hermine",
  "lina",
  "ingeburg",
  "brigitta",
  "jessica",
  "karla",
  "grete",
  "frauke",
  "helena",
  "margrit",
  "alice",
  "magdalene",
  "kristina",
  "beatrix",
  "jennifer",
  "hella",
  "miriam",
  "walburga",
  "friederike",
  "margitta",
  "marlis",
  "natalie",
  "eveline",
  "nadja",
  "sarah",
  "emilie",
  "meike",
  "edelgard",
  "emmi",
  "doreen",
  "ulla",
  "sophie",
  "ursel",
  "esther",
  "ilka",
  "marta",
  "viola",
  "jasmin",
  "helma",
  "linda",
  "mandy",
  "natalia",
  "edda",
  "carolin",
  "wilhelmine",
  "annelies",
  "hilda",
  "maike",
  "lilli",
  "cordula",
  "lucia",
  "karen",
  "hiltrud",
  "lidia",
  "tamara",
  "annerose",
  "caroline",
  "margareta",
  "irmtraud",
  "edeltraut",
  "käte",
  "karina",
  "antonia",
  "susann",
  "ottilie",
  "evelin",
  "jacqueline",
  "viktoria",
  "janine",
  "annelie",
  "hertha",
  "liesel",
  "rosi",
  "inga",
  "magda",
  "grit",
  "gunda",
  "lucie",
  "josefa",
  "alma",
  "karoline",
  "meta",
  "henriette",
  "rosmarie",
  "wiebke",
  "uschi",
  "birgitt",
  "peggy",
  "adele",
  "natascha",
  "elly",
  "sigrun",
  "elise",
  "dörte",
  "gertraude",
  "kristin",
  "jeanette",
  "antonie",
  "gretel",
  "betty",
  "isabel",
  "lena",
  "constanze",
  "jenny",
  "isabella",
  "carina",
  "gundula",
  "elfi",
  "rosel",
  "janina",
  "galina",
  "gitta",
  "susan",
  "denise",
  "traute",
  "evi",
  "beatrice",
  "annika",
  "anna-maria",
  "liesbeth",
  "svetlana",
  "wendelin",
  "larissa",
  "leni",
  "swetlana",
  "sofie",
  "sylke",
  "bianka",
  "carla",
  "resi",
  "ludmilla",
  "siegrid",
  "pauline",
  "frida",
  "vanessa",
  "rosina",
  "dorit",
  "anett",
  "melitta",
  "auguste",
  "mareike",
  "bernadette",
  "annelore",
  "ivonne",
  "ria",
  "irena",
  "siglinde",
  "laura",
  "klaudia",
  "babette",
  "sofia",
  "lisbeth",
  "almut",
  "susanna",
  "wanda",
  "svenja",
  "giesela",
  "emmy",
  "kathleen",
  "fatma",
  "luzia",
  "nelli",
  "christl",
  "ariane",
  "jeannette",
  "corina",
  "saskia",
  "rebecca",
  "isabell",
  "wally",
  "hanne",
  "thekla",
  "karolina",
  "felicitas",
  "cäcilia",
  "valeri",
  "conny",
  "gesine",
  "selma",
  "birte",
  "marliese",
  "luzie",
  "hedi",
  "romy",
  "anny",
  "agathe",
  "nora",
  "kirstin",
  "cäcilie",
  "ingelore",
  "dana",
  "teresa",
  "marika",
  "nancy",
  "alwine",
  "amalie",
  "lilo",
  "imke",
  "margarita",
  "johanne",
  "henny",
  "margaretha",
  "maja",
  "christin",
  "rose",
  "gertrude",
  "anika",
  "ricarda",
  "mirjam",
  "gerti",
  "danuta",
  "sabina",
  "ayse",
  "maritta",
  "ludmila",
  "sina",
  "loni",
  "isabelle",
  "marija",
  "theresa",
  "nathalie",
  "eugenie",
  "sandy",
  "sophia",
  "monique",
  "minna",
  "marietta",
  "leonore",
  "ewa",
  "reinhild",
  "pamela",
  "reinhilde",
  "isa",
  "gerta",
  "mina",
  "trude",
  "henri",
  "janet",
  "natalja",
  "kunigunde",
  "wiltrud",
  "sara",
  "irmtraut",
  "emine",
  "gesa",
  "änne",
  "ortrud",
  "heiderose",
  "hatice",
  "silvana",
  "ana",
  "mona",
  "birgitta",
  "brunhild",
  "ernestine",
  "aloisia",
  "lilly",
  "halina",
  "valerie",
  "konstanze",
  "monica",
  "joanna",
  "emilia",
  "rosalinde",
  "katarina",
  "evelyne",
  "irmhild",
  "cindy",
  "kati",
  "catrin",
  "inka",
  "rosita",
  "jaqueline",
  "dunja",
  "simona",
  "madeleine",
  "jolanta",
  "dietlinde",
  "helen",
  "gerhild",
  "cathrin",
  "inna",
  "marlen",
  "krystyna",
  "manja",
  "patrizia",
  "centa",
  "traudel",
  "wera",
  "anastasia",
  "renata",
  "ira",
  "berit",
  "zita",
  "rosalia",
  "leonie",
  "grazyna",
  "anne-marie",
  "diane",
  "eugenia",
  "amalia",
  "alina",
  "brita",
  "nelly",
  "henrike",
  "mira",
  "walli",
  "mechtild",
  "amanda",
  "traudl",
  "christiana",
  "kristine",
  "victoria",
  "dina",
  "traude",
  "myriam",
  "almuth",
  "solveig",
  "alla",
  "lioba",
  "juliana",
  "rosalie",
  "undine",
  "gunhild",
  "dörthe",
  "editha",
  "bozena",
  "herma",
  "fanny",
  "clara",
  "rebekka",
  "luitgard",
  "lidija",
  "julie",
  "annemie",
  "mary",
  "alexa",
  "paulina",
  "sibille",
  "heidelore",
  "beata",
  "catherine",
  "anka",
  "theodora",
  "gerdi",
  "bernhardine",
  "roswita",
  "aenne",
  "susi",
  "maria-luise",
  "malgorzata",
  "edeltrud",
  "hedda",
  "kordula",
  "kathi",
  "michelle",
  "dietlind",
  "mariechen",
  "oxana",
  "mara",
  "lotti",
  "olivia",
  "eleonora",
  "betti",
  "ekaterina",
  "anne-kathrin",
  "nada",
  "angelina",
  "madlen",
  "rotraud",
  "mariola",
  "gretchen",
  "gerlind",
  "gloria",
  "stella",
  "jelena",
  "louise",
  "greta",
  "magarete",
  "fatima",
  "catharina",
  "tatiana",
  "irmela",
  "dorothe",
  "gina",
  "insa",
  "aline",
  "elzbieta",
  "lea",
  "romana",
  "gisa",
  "senta",
  "dolores",
  "marieluise",
  "luisa",
  "betina",
  "kreszenz",
  "dorle",
  "josephine",
  "desiree",
  "annedore",
  "olav",
  "mia",
  "inken",
  "mechthilde",
  "cilli",
  "violetta",
  "nadia",
  "janette",
  "iwona",
  "eleni",
  "dorota",
  "gundi",
  "henni",
  "annelise",
  "belinda",
  "mirjana",
  "siegrun",
  "georgia",
  "kläre",
  "elisa",
  "hildegund",
  "rose-marie",
  "vesna",
  "adriana",
  "milena",
  "jane",
  "friedl",
  "rena",
  "norma",
  "anne-katrin",
  "hannah",
  "waldtraut",
  "elfie",
  "tabea",
  "aurelia",
  "alena",
  "margita",
  "delia",
  "jeannine",
  "asta",
  "irmengard",
  "anneli",
  "karolin",
  "hülya",
  "valeria",
  "heidelinde",
  "ilonka",
  "dragica",
  "wilfriede",
  "gordana",
  "felizitas",
  "agnieszka",
  "cilly",
  "swantje",
  "urte",
  "hubertine",
  "adelgunde",
  "freya",
  "irmi",
  "marianna",
  "zeynep",
  "cristina",
  "bertha",
  "carmine",
  "yvette",
  "sevim",
  "zenta",
  "lilian",
  "magret",
  "mirja",
  "cora",
  "yasmin",
  "jadwiga",
  "rotraut",
  "clarissa",
  "raphaela",
  "liesa",
  "birthe",
  "eileen",
  "wibke",
  "georgine",
  "leyla",
  "justine",
  "cathleen",
  "katarzyna",
  "lilija",
  "sonia",
  "franka",
  "aynur",
  "margaret",
  "yasemin",
  "ingetraud",
  "philomena",
  "aleksandra",
  "lili",
  "liliane",
  "gilda",
  "carmela",
  "filiz",
  "britt",
  "gudula",
  "priska",
  "raisa",
  "albertine",
  "janett",
  "michaele",
  "hedy",
  "marie-theres",
  "camilla",
  "kreszentia",
  "claire",
  "krista",
  "kunigunda",
  "carolina",
  "annelene",
  "olena",
  "ljubica",
  "gretl",
  "aysel",
  "xenia",
  "blanka",
  "janna",
  "hiltraud",
  "suse",
  "apollonia",
  "benita",
  "virginia",
  "katherina",
  "gislinde",
  "monja",
  "slavica",
  "carin",
  "elizabeth",
  "mariana",
  "brit",
  "katy",
  "elif",
  "hildegunde",
  "meryem",
  "danica",
  "klothilde",
  "roselinde",
  "irmtrud",
  "notburga",
  "freia",
  "flora",
  "rabea",
  "hildegart",
  "jasmina",
  "alicja",
  "deborah",
  "nadin",
  "alwina",
  "chantal",
  "mariele",
  "hildburg",
  "mareen",
  "stefani",
  "reingard",
  "amelie",
  "maria-theresia",
  "gertraut",
  "caren",
  "sylvie",
  "veronica",
  "maya",
  "harriet",
  "dajana",
  "francesca",
  "jo",
  "eva-marie",
  "lidwina",
  "kira",
  "marisa",
  "hulda",
  "erdmute",
  "cecilia",
  "anica",
  "gusti",
  "gundel",
  "lia",
  "leopoldine",
  "rosl",
  "giovanna",
  "ljiljana",
  "ivanka",
  "raissa",
  "birgid",
  "melita",
  "friedhilde",
  "kriemhild",
  "ellinor",
  "marzena",
  "lisette",
  "friedlinde",
  "suzanne",
  "leokadia",
  "tania",
  "theda",
  "genoveva",
  "ayten",
  "annie",
  "sinaida",
  "marica",
  "stefania",
  "agatha",
  "rosa-maria",
  "francoise",
  "hanne-lore",
  "frederike",
  "adelinde",
  "valeska",
  "babett",
  "marie-louise",
  "zdenka",
  "justina",
  "katalin",
  "marcella",
  "dorina",
  "caterina",
  "katharine",
  "heide-marie",
  "angelique",
  "waltrud",
  "milka",
  "cosima",
  "ljudmila",
  "ann",
  "liliana",
  "constance",
  "danielle",
  "paola",
  "ortrun",
  "nermin",
  "gesche",
  "sibylla",
  "ivana",
  "hannchen",
  "wenke",
  "tilly",
  "jolanthe",
  "annaliese",
  "meral",
  "theres",
  "erica",
  "cläre",
  "silja",
  "gülay",
  "sibilla",
  "gabriella",
  "adina",
  "marjan",
  "ludwina",
  "jessika",
  "natali",
  "antoinette",
  "zofia",
  "franca",
  "corinne",
  "trudel",
  "ingetraut",
  "natalija",
  "lene",
  "hanny",
  "annekatrin",
  "kata",
  "arzu",
  "giuseppina",
  "irmingard",
  "adriane",
  "wencke",
  "adolfine",
  "nuray",
  "nadeschda",
  "melissa",
  "ester",
  "adeline",
  "stilla",
  "lissy",
  "oda",
  "branka",
  "damaris",
  "serpil",
  "leila",
  "nadeshda",
  "martine",
  "ehrentraud",
  "christa-maria",
  "antonina",
  "bruni",
  "mirella",
  "songül",
  "luka",
  "milica",
  "geraldine",
  "tülay",
  "lara",
  "hilma",
  "evamaria",
  "marit",
  "edit",
  "cynthia",
  "susana",
  "gülsen",
  "semra",
  "concetta",
  "trudi",
  "sylvana",
  "anna-luise",
  "agata",
  "gitte",
  "pascale",
  "lissi",
  "korinna",
  "alida",
  "zehra",
  "imelda",
  "sylwia",
  "donata",
  "florentine",
  "fabienne",
  "siegried",
  "hanife",
  "herlinde",
  "viviane",
  "mareile",
  "kathy",
  "joana",
  "florence",
  "jolanda",
  "ann-kathrin",
  "antonietta",
  "annegrete",
  "aneta",
  "nurten",
  "anneke",
  "türkan",
  "elisabet",
  "daria",
  "veronique",
  "mercedes",
  "geza",
  "fadime",
  "albina",
  "miroslawa",
  "janin",
  "elma",
  "urszula",
  "polina",
  "katerina",
  "anna-lena",
  "marie-therese",
  "käthi",
  "ildiko",
  "telse",
  "ruthild",
  "nuran",
  "gönül",
  "angelica",
  "elwira",
  "elenore",
  "samira",
  "magrit",
  "lieschen",
  "ingried",
  "felicia",
  "friedericke",
  "annamaria",
  "silva",
  "augusta",
  "nevenka",
  "svea",
  "anne-rose",
  "valentine",
  "evangelia",
  "helmtrud",
  "annita",
  "walentina",
  "sarina",
  "radmila",
  "laila",
  "canan",
  "anna-marie",
  "etta",
  "sibel",
  "loretta",
  "marleen",
  "kristiane",
  "rahel",
  "sybilla",
  "reni",
  "gülten",
  "alicia",
  "peter",
  "wolfgang",
  "michael",
  "werner",
  "klaus",
  "thomas",
  "manfred",
  "helmut",
  "jürgen",
  "heinz",
  "gerhard",
  "andreas",
  "hans",
  "josef",
  "günter",
  "dieter",
  "horst",
  "walter",
  "frank",
  "bernd",
  "karl",
  "herbert",
  "franz",
  "martin",
  "uwe",
  "georg",
  "heinrich",
  "stefan",
  "christian",
  "karl-heinz",
  "rudolf",
  "kurt",
  "hermann",
  "johann",
  "wilhelm",
  "siegfried",
  "rolf",
  "joachim",
  "alfred",
  "rainer",
  "jörg",
  "ralf",
  "erich",
  "norbert",
  "bernhard",
  "willi",
  "alexander",
  "ulrich",
  "markus",
  "matthias",
  "harald",
  "paul",
  "roland",
  "ernst",
  "reinhard",
  "günther",
  "gerd",
  "fritz",
  "otto",
  "friedrich",
  "erwin",
  "lothar",
  "robert",
  "dirk",
  "johannes",
  "volker",
  "wilfried",
  "richard",
  "anton",
  "jens",
  "hans-jürgen",
  "hubert",
  "udo",
  "holger",
  "albert",
  "ludwig",
  "dietmar",
  "hartmut",
  "reinhold",
  "hans-joachim",
  "adolf",
  "detlef",
  "oliver",
  "christoph",
  "stephan",
  "axel",
  "reiner",
  "alois",
  "eberhard",
  "waldemar",
  "heiko",
  "daniel",
  "torsten",
  "sven",
  "bruno",
  "olaf",
  "mario",
  "konrad",
  "steffen",
  "ingo",
  "jochen",
  "thorsten",
  "max",
  "alfons",
  "rüdiger",
  "carsten",
  "viktor",
  "hans-peter",
  "rudi",
  "friedhelm",
  "armin",
  "jan",
  "lutz",
  "ewald",
  "winfried",
  "egon",
  "erhard",
  "sebastian",
  "jakob",
  "marco",
  "harry",
  "eduard",
  "eugen",
  "karlheinz",
  "andre",
  "klaus-dieter",
  "achim",
  "karsten",
  "edgar",
  "claus",
  "hans-dieter",
  "tobias",
  "theo",
  "mathias",
  "gottfried",
  "emil",
  "guido",
  "arno",
  "marc",
  "eckhard",
  "marcus",
  "gustav",
  "florian",
  "dietrich",
  "theodor",
  "berthold",
  "burkhard",
  "rene",
  "artur",
  "august",
  "edmund",
  "arnold",
  "franz-josef",
  "lars",
  "patrick",
  "willy",
  "ferdinand",
  "gerald",
  "ralph",
  "hans-georg",
  "fred",
  "leo",
  "bodo",
  "hugo",
  "philipp",
  "klaus-peter",
  "ronald",
  "maik",
  "oskar",
  "mike",
  "david",
  "gert",
  "roman",
  "björn",
  "wolfram",
  "hans-werner",
  "arthur",
  "gregor",
  "henning",
  "nikolaus",
  "marcel",
  "felix",
  "elmar",
  "edwin",
  "gunter",
  "heribert",
  "leonhard",
  "adam",
  "raimund",
  "manuel",
  "albrecht",
  "clemens",
  "ludger",
  "henry",
  "heiner",
  "ulf",
  "timo",
  "antonio",
  "lorenz",
  "benjamin",
  "detlev",
  "mark",
  "dennis",
  "oswald",
  "simon",
  "benno",
  "engelbert",
  "ronny",
  "mehmet",
  "xaver",
  "jörn",
  "ali",
  "gerold",
  "toni",
  "helmuth",
  "christof",
  "sergej",
  "volkmar",
  "mirko",
  "tim",
  "andré",
  "marko",
  "gernot",
  "boris",
  "hans-jörg",
  "vladimir",
  "mustafa",
  "knut",
  "willibald",
  "dominik",
  "hans-ulrich",
  "ottmar",
  "hubertus",
  "giuseppe",
  "heinz-dieter",
  "otmar",
  "heino",
  "enrico",
  "siegmund",
  "julius",
  "hendrik",
  "hans-günter",
  "alwin",
  "heinz-jürgen",
  "rené",
  "hartwig",
  "erik",
  "nils",
  "klemens",
  "wladimir",
  "helge",
  "hans-josef",
  "ivan",
  "roger",
  "siegbert",
  "hilmar",
  "maximilian",
  "falk",
  "valentin",
  "gunther",
  "rupert",
  "hans-hermann",
  "arne",
  "ingolf",
  "gunnar",
  "karl",
  "hermann-josef",
  "thilo",
  "adalbert",
  "alex",
  "tino",
  "andrej",
  "salvatore",
  "hans-heinrich",
  "nico",
  "eric",
  "konstantin",
  "ahmet",
  "joseph",
  "hinrich",
  "egbert",
  "fabian",
  "hasan",
  "ekkehard",
  "marian",
  "hansjörg",
  "christopher",
  "francesco",
  "hagen",
  "silvio",
  "wolf",
  "siegmar",
  "nikolai",
  "klaus-jürgen",
  "john",
  "heinz-peter",
  "georgios",
  "bert",
  "benedikt",
  "gisbert",
  "adrian",
  "leopold",
  "karl-josef",
  "giovanni",
  "eckart",
  "igor",
  "tilo",
  "ibrahim",
  "reimund",
  "aloys",
  "marek",
  "carl",
  "gebhard",
  "ullrich",
  "wolf-dieter",
  "juri",
  "gotthard",
  "hüseyin",
  "hanni",
  "roberto",
  "bertram",
  "henrik",
  "hans-gerd",
  "hans",
  "hannes",
  "dimitrios",
  "albin",
  "hans",
  "arnd",
  "hans-otto",
  "arndt",
  "victor",
  "uli",
  "friedrich-wilhelm",
  "sönke",
  "horst-dieter",
  "hans-günther",
  "bastian",
  "vincenzo",
  "ismail",
  "norman",
  "heinz-josef",
  "karl-friedrich",
  "denis",
  "marius",
  "antonius",
  "frieder",
  "ansgar",
  "angelo",
  "tom",
  "rolf-dieter",
  "harri",
  "malte",
  "rico",
  "götz",
  "reinhardt",
  "hellmut",
  "bernard",
  "pascal",
  "falko",
  "fridolin",
  "anatoli",
  "milan",
  "eckard",
  "rafael",
  "moritz",
  "friedbert",
  "murat",
  "kai-uwe",
  "hardy",
  "sören",
  "hans-martin",
  "vitali",
  "jose",
  "heinz-günter",
  "luigi",
  "julian",
  "henryk",
  "heinz-werner",
  "klaus",
  "edward",
  "burghard",
  "ortwin",
  "hans-walter",
  "christos",
  "konstantinos",
  "pierre",
  "philip",
  "kaspar",
  "kevin",
  "gabriel",
  "leonid",
  "hans-wilhelm",
  "friedemann",
  "hanno",
  "kuno",
  "osman",
  "niels",
  "herwig",
  "dierk",
  "meinolf",
  "emanuel",
  "nikola",
  "fredi",
  "meinhard",
  "carlo",
  "claus-dieter",
  "guenter",
  "cornelius",
  "raphael",
  "hanspeter",
  "gero",
  "andy",
  "mohamed",
  "torben",
  "domenico",
  "josip",
  "hans-j.",
  "diethelm",
  "swen",
  "eckehard",
  "till",
  "lukas",
  "berndt",
  "enno",
  "nikolaos",
  "hans",
  "jens-uwe",
  "michel",
  "franz",
  "vinzenz",
  "sigurd",
  "nikolaj",
  "stanislaw",
  "gottlieb",
  "andree",
  "wulf",
  "diethard",
  "robin",
  "carlos",
  "veit",
  "franco",
  "heinz-georg",
  "nicolas",
  "ivo",
  "dimitri",
  "danny",
  "jonas",
  "steven",
  "andrzej",
  "karl-otto",
  "walther",
  "klaus",
  "harro",
  "janusz",
  "hans-christian",
  "sandro",
  "hans-jochen",
  "eckhardt",
  "jost",
  "karl-wilhelm",
  "yusuf",
  "wenzel",
  "bogdan",
  "guiseppe",
  "gilbert",
  "ernst-august",
  "ehrenfried",
  "ignaz",
  "wieland",
  "hans",
  "karl-ludwig",
  "karl-ernst",
  "hellmuth",
  "darius",
  "karl-heinrich",
  "magnus",
  "helmar",
  "metin",
  "arnulf",
  "mirco",
  "juergen",
  "miroslaw",
  "sigmund",
  "claus-peter",
  "claudio",
  "pietro",
  "william",
  "heinz-joachim",
  "jonas",
  "halil",
  "ramazan",
  "ahmed",
  "hanns",
  "miroslav",
  "piotr",
  "peer",
  "helfried",
  "hans",
  "samuel",
  "jerzy",
  "karl-hermann",
  "kemal",
  "dragan",
  "mohammad",
  "heini",
  "hans-willi",
  "jacek",
  "zbigniew",
  "rocco",
  "ioannis",
  "otfried",
  "gordon",
  "james",
  "bernward",
  "joerg",
  "bertold",
  "meinrad",
  "gerhardt",
  "conrad",
  "francisco",
  "hasso",
  "zoran",
  "wolf-rüdiger",
  "matthäus",
  "meik",
  "fernando",
  "jens-peter",
  "hansjürgen",
  "sepp",
  "roy",
  "lambert",
  "guntram",
  "diedrich",
  "yilmaz",
  "damian",
  "constantin",
  "abdullah",
  "stanislaus",
  "ole",
  "andrei",
  "sieghard",
  "karl-peter",
  "steve",
  "patrik",
  "jurij",
  "reimer",
  "cord",
  "ismet",
  "iwan",
  "niko",
  "tilman",
  "ömer",
  "volkhard",
  "raymond",
  "burkhardt",
  "pasquale",
  "nicolai",
  "kilian",
  "pius",
  "erol",
  "wolfhard",
  "branko",
  "adelbert",
  "ditmar",
  "paolo",
  "stephen",
  "jacob",
  "hans-michael",
  "valerij",
  "ahmad",
  "german",
  "dominic",
  "harm",
  "hajo",
  "stjepan",
  "zeljko",
  "hassan",
  "pavel",
  "patric",
  "orhan",
  "herrmann",
  "reinhart",
  "laszlo",
  "hans-herbert",
  "hans-helmut",
  "mohammed",
  "tadeusz",
  "claudius",
  "athanasios",
  "fredy",
  "luis",
  "randolf",
  "andrew",
  "george",
  "krzysztof",
  "petar",
  "ferdi",
  "goran",
  "curt",
  "baldur",
  "hans-erich",
  "karl-hans",
  "charles",
  "kristian",
  "arnim",
  "frederik",
  "timm",
  "ricardo",
  "carmelo",
  "alf",
  "janos",
  "gerhart",
  "carl-heinz",
  "juan",
  "kunibert",
  "gundolf",
  "süleyman",
  "aribert",
  "hakan",
  "stanislav",
  "alexej",
  "tibor",
  "heinz-willi",
  "mahmut",
  "hans-henning",
  "holm",
  "ramon",
  "ante",
  "adem",
  "hans-gerhard",
  "dariusz",
  "urban",
  "pedro",
  "hans-uwe",
  "gaetano",
  "hans-ludwig",
  "burkard",
  "hans-dietrich",
  "hans-friedrich",
  "jaroslaw",
  "hans-karl",
  "wolf-dietrich",
  "heinz-günther",
  "henner",
  "tomislav",
  "vitus",
  "toralf",
  "sergei",
  "freddy",
  "ingmar",
  "salih",
  "walfried",
  "josé",
  "dittmar",
  "recep",
  "erhardt",
  "istvan",
  "birger",
  "ottomar",
  "heinz-gerd",
  "tomas",
  "justus",
  "vito",
  "ryszard",
  "evangelos",
  "dusan",
  "anatolij",
  "aleksander",
  "sami",
  "zoltan",
  "cengiz",
  "raik",
  "hartmuth",
  "sigmar",
  "leszek",
  "leon",
  "cemal",
  "sylvio",
  "hans-albert",
  "eckhart",
  "wieslaw",
  "frank-michael",
  "willfried",
  "leonard",
  "rolf-peter",
  "ralf-peter",
  "franjo",
  "othmar",
  "karl-dieter",
  "brian",
  "anselm",
  "fedor",
  "slavko",
  "calogero",
  "alban",
  "kasimir",
  "vassilios",
  "emmerich",
  "niklas",
  "danilo",
  "pawel",
  "harold",
  "hans-rudolf",
  "tilmann",
  "hermann",
  "vadim",
  "theodoros",
  "tony",
  "miguel",
  "michail",
  "klaus-d.",
  "donald",
  "cosimo",
  "guenther",
  "frithjof",
  "slobodan",
  "robby",
  "kornelius",
  "ilja",
  "jochem",
  "leander",
  "ilija",
  "alberto",
  "tomasz",
  "wojciech",
  "bernfried",
  "theobald",
  "attila",
  "franz-peter",
  "louis",
  "heinz-wilhelm",
  "witold",
  "jobst",
  "ernst-otto",
  "erdogan",
  "karl-georg",
  "eberhardt",
  "hans-rainer",
  "traugott",
  "oswin",
  "burckhard",
  "anthony",
  "hans-theo",
  "paul-gerhard",
  "reimar",
  "jonathan",
  "anastasios",
  "thaddäus",
  "franz-xaver",
  "alessandro",
  "enver",
  "romuald",
  "hans-bernd",
  "drago",
  "hans-h.",
  "aleksandr",
  "alexandre",
  "augustin",
  "thies",
  "jan-peter",
  "frederic",
  "sergio",
  "heinz-otto",
  "gerard",
  "maurice",
  "karl-august",
  "friedhold",
  "denny",
  "balthasar",
  "samir",
  "bayram",
  "jaroslav",
  "filippo",
  "erkan",
  "mato",
  "jozef",
  "jiri",
  "dagobert",
  "aziz",
  "zeki",
  "bartholomäus",
  "leonardo",
  "isidor",
  "hansgeorg",
  "sahin",
  "muzaffer",
  "claas",
  "nick",
  "sandor",
  "gottlob",
  "jann",
  "arif",
  "karl-werner",
  "horst-günter",
  "erdal",
  "stefano",
  "marijan",
  "saban",
  "mariusz",
  "kenan",
  "daniele",
  "urs",
  "ercan",
  "slawomir",
  "remo",
  "amir",
  "zlatko",
  "jorge",
  "alfredo",
  "ilias",
  "ladislaus",
  "sabri",
  "laurenz",
  "maurizio",
  "diether",
  "anatol",
  "linus",
  "edelbert",
  "bernt",
  "darko",
  "jörg-peter",
  "heinz",
  "dan",
  "thoralf",
  "johan",
  "phillip",
  "horst-peter",
  "heinfried",
  "eitel",
  "luciano",
  "ottokar",
  "bekir",
  "friedrich-karl",
  "gotthilf",
  "gerfried",
  "antonino",
  "hans-wolfgang",
  "baptist",
  "hans",
  "antonios",
  "wigbert",
  "philippe",
  "hans",
  "ben",
  "czeslaw",
  "kamil",
  "gerwin",
  "micha",
  "jörgen",
  "frank-peter",
  "ernst-dieter",
  "rochus",
  "geert",
  "gabor",
  "folker",
  "vlado",
  "korbinian",
  "ferenc",
  "ekrem",
  "stavros",
  "renato",
  "raymund",
  "celal",
  "vincent",
  "mikhail",
  "ingbert",
  "karlfried",
  "andres",
  "nikolas",
  "ottfried",
  "hans-adolf",
  "klaus-werner",
  "petros",
  "alexandros",
  "immo",
  "severin",
  "necati",
  "leif",
  "paul-heinz",
  "massimo",
  "kenneth",
  "dogan",
  "sylvester",
  "hansjoachim",
  "maxim",
  "knud",
  "sigfried",
  "siegward",
  "sedat",
  "mahmoud",
  "gotthold",
  "gereon",
  "hans-eberhard",
  "alan",
  "reza",
  "folkert",
  "ehrhard",
  "emin",
  "apostolos",
  "heinz-walter",
  "utz",
  "veli",
  "nicolaus",
  "klaas",
  "h.-dieter",
  "rudolph",
  "serge",
  "jovan",
  "abdul",
  "kazim",
  "klaus-michael",
  "alfonso",
  "aleksandar",
  "roderich",
  "rouven",
  "hans-detlef",
  "berend",
  "tassilo",
  "sükrü",
  "gunar",
  "vinko",
  "ruben",
  "ron",
  "andrey",
  "aldo",
  "paulo",
  "peter-michael",
  "heimo",
  "ernest",
  "anto",
  "woldemar",
  "selim",
  "dursun",
  "engin",
  "abraham",
  "reinald",
  "mattias",
  "janko",
  "ekkehart",
  "bela",
  "aleksej",
  "silvester",
  "janus",
  "ilhan",
  "elias",
  "giorgio",
  "dino",
  "pero",
  "muharrem",
  "mesut",
  "lorenzo",
  "thorben",
  "rigo",
  "oscar",
  "gino",
  "matteo",
  "klaus-ulrich",
  "francis",
  "zdravko",
  "reginald",
  "mehdi",
  "karl-jürgen",
  "cetin",
  "milos",
  "friedo",
  "nuri",
  "nurettin",
  "eggert",
  "ralf-dieter",
  "pirmin",
  "bernd-dieter",
  "zenon",
  "senol",
  "klaus-günter",
  "cemil",
  "abram",
  "sebastiano",
  "mijo",
  "grzegorz",
  "gerolf",
  "arnfried",
  "sigismund",
  "riza",
  "miodrag",
  "faruk",
  "imre",
  "marten",
  "hans-hinrich",
  "abbas",
  "tillmann",
  "marvin",
  "ignatz",
  "kay-uwe",
  "alexei",
  "willibert",
  "vittorio",
  "fredo",
  "fatih",
]);

export const checkStringForPIIName = (cell: UploadDQCDataType, str: string): PiiIssue | null => {
  for (let partialStr of str.toLocaleLowerCase().split(" ")) {
    if (t.has(partialStr))
      return {
        row: cell.row,
        column: cell.column,
        category: "Name",
        type: "sensitive",
        comment: "sensitive_comment",
        severity: "info",
        isIgnored: false,
      };
    continue;
  }
  return null;
};
